import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import ProductRowMobile from "views/Account/ProductRowMobile";
import LinkRowMobile from "views/Account/LinkRowMobile";
import AccountBanner from "components/AccountBanner";
import ImageWrapper from "components/ImageWrapper";
import InformationIcon from "components/icons/InformationIcon";
import EditProductModal from "components/EditProductModal/EditProductModal";
import EditLinkModal from "components/EditProductModal/EditLinkModal";
import Modal from "../../components/Modal";
import ShareBlock from "components/Share/ShareBlock";
import MerchantShareBlock from "components/Share/MerchantShareBlock";

import MyProductsItemDropdown from "views/Account/MyProductsItemDropdown";
import LinkItemDropdown from "views/Account/LinkItemDropdown";
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import { toggleProductPageModal, toggleShareModal } from "stateManagement/reducers/modalReducer";
import { currencyFormat, titleCase } from "sharedUtils/productUtils";
import { getProductShareLink, getReferralShareLink } from "../../sharedUtils/shareLinkPrefixes"
import LoadSpinner from "components/LoadSpinner";
import LoadSpinnerOverlay from "components/LoadSpinnerOverlay";
import NoResults from "components/NoResults";
import toast, { Toaster } from 'react-hot-toast';
import { Popover, UncontrolledPopover, PopoverBody } from "reactstrap";

import CloseIcon from "components/icons/CloseIcon";
import SaleIcon from "components/icons/SaleIcon";
import { Helmet } from 'react-helmet';
import ActionButton from "../../components/ActionButton";


class MyProducts extends React.Component {
    constructor() {
        super();
        this.state = {
            getLinkPopover: false,
            products: [],
            filteredProducts: [],
            links: [],
            filteredLinks: [],
            productsLinks: [],
            showEditProduct: false,
            showEditLink: false,
            productToEditIndex: 0,
            sectors: [],
            loading: true,
            loadingMore: false,
            modalOpen: false,
            linkToEdite: null,
            shareLink: "",
            isUserMerchant: false,
            tab: 0,
            filter: "",
            reset: 0,
            addedProduct: null,
            addingProduct: false,
            searchIcon: 0,
            mostRecentSearch: "",
            loadingLinkUpload: false,
        }
    }

    async componentDidMount() {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }

        if (this.props.tab) {
            this.setState({ tab: this.props.tab });
        }

        if (!this.props.creatorDashboard) {
            window.scrollTo(scrollOptions);
        }

        this.setState({ loading: true });

        let products = await this.fetchProducts();
        let sectors = await this.fetchSectors();
        let links = await this.fetchLinks();

        // Combine products and links
        let combinedArray = [...links, ...products];

        // Helper function to check if a date is valid
        
        // Sort the combined array
        let filteredProductsLinks = combinedArray.sort((a, b) => {
            let dateA = this.isValidDate(new Date(a.modifiedDate)) ? new Date(a.modifiedDate) : new Date(a.createdDate);
            let dateB = this.isValidDate(new Date(b.modifiedDate)) ? new Date(b.modifiedDate) : new Date(b.createdDate);

            return dateB - dateA;
        });


        this.setState({ products, sectors, loading: false, filteredProducts: products, filteredProductsLinks, productsLinks: filteredProductsLinks, links, filteredLinks: links });

        document.addEventListener("_productAddedEvent", this.updateProductsAndLinks)
        window.addEventListener('storage', () => {
            var productAdded = new Date(window.localStorage.getItem('productAdded'));
            if (productAdded < new Date()) {
                this.updateProductsAndLinks();
            }
        });
    }

    isValidDate = (date) => {
        return date instanceof Date && !isNaN(date);
    }

    updateProductsAndLinks = async () => {
        this.setState({ loading: true });
        this.updateProducts();
        let links = await this.fetchLinks();
        let products = await this.fetchProducts();

        let combinedArray = [...links, ...products];

        // Helper function to check if a date is valid

        // Sort the combined array
        let filteredProductsLinks = combinedArray.sort((a, b) => {
            let dateA = this.isValidDate(new Date(a.modifiedDate)) ? new Date(a.modifiedDate) : new Date(a.createdDate);
            let dateB = this.isValidDate(new Date(b.modifiedDate)) ? new Date(b.modifiedDate) : new Date(b.createdDate);

            return dateB - dateA;
        });
        this.setState({ filteredProductsLinks, productLinks: filteredProductsLinks, links, products, loading: false })
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.reset !== this.state.reset) {
            this.updateProducts();
        }
        if (prevState.searchIcon !== this.state.searchIcon && (this.state.mostRecentSearch !== "" || (this.state.mostRecentSearch === "" && this.state.filter !== ""))) {
            this.updateProducts();
        }
    }

    updateProducts = async () => {
        this.setState({ loading: true });

        let filteredProductsLinks = this.state.productsLinks;
        let filter = this.state.filter ?? "";

        filteredProductsLinks = filteredProductsLinks.filter(p => {
            const filterLowerCase = filter.toLowerCase();
            return (p.merchantName?.toLowerCase().includes(filterLowerCase) ?? false) ||
                ((!!(p.logoUrl) && p.description?.toLowerCase().includes(filterLowerCase)) ?? false) ||
                (p.manufacturer?.toLowerCase().includes(filterLowerCase) ?? false) ||
                (p.name?.toLowerCase().includes(filterLowerCase) ?? false) ||
                (p.merchant?.toLowerCase().includes(filterLowerCase) ?? false);
        });

        filteredProductsLinks = filteredProductsLinks.sort((a, b) => {
            let dateA = this.isValidDate(new Date(a.modifiedDate)) ? new Date(a.modifiedDate) : new Date(a.createdDate);
            let dateB = this.isValidDate(new Date(b.modifiedDate)) ? new Date(b.modifiedDate) : new Date(b.createdDate);

            return dateB - dateA;
        });

        this.setState({ loading: false, filteredProductsLinks });
    }

    componentWillUnmount() {
        document.removeEventListener("_productAddedEvent", this.updateProductsAndLinks);
        window.removeEventListener("storage", () => { });
    }


    fetchSectors = async () => {
        let url = `api/Sector/GetSectors`;
        let sectors = await getRequest(url);
        sectors = sectors.sort((a, b) => { if (a.name < b.name) return -1; });
        if (sectors === undefined) {
            return [];
        }
        else {
            return sectors;
        }
    }

    hide = async (i) => {
        let productId = this.state.filteredProductsLinks[i].userMerchantProductId;
        let product = { ...this.state.filteredProductsLinks[i] };

        const filteredProductsLinks = this.state.filteredProductsLinks.filter(p => p.userMerchantProductId !== productId || !!(p.logoUrl))
        const products = this.state.products.filter(p => p.userMerchantProductId !== productId)
        this.setState({ products, filteredProductsLinks });

        let url = `/Products/EditUserMerchantProduct/${productId}`;

        let payload = {
            id: product.userMerchantProductId,
            userDescription: product.userDescription,
            merchantProductId: product.merchantProductId,
            coupon: product.shareCode,
            userId: this.props.loggedInUser.id,
            isHidden: 1
        }

        await postRequest(url, payload);

    }

    getLinkError = () => {
        toast.error(
            <div className="p-2">
                <h4 className="text-center">Product currently unavailable</h4>
            </div>,
            {
                duration: 3000,
                position: 'top-center',
                icon: null,
            }
        );
    }

    handleGetLink = (shareLink) => {
        navigator.clipboard.writeText(shareLink);

        toast.success(
            <div className="p-2">
                <h4 className="text-center">Commissionable Link Copied to Clipboard!</h4>
                <p className="text-center mx-auto px-2 pt-2 pb-">Your commissionable link has been copied to clipboard and is ready to be shared</p>
            </div>,
            {
                duration: 3000,
                position: 'top-center',
                icon: null,
            }
        );
        this.handleGoogleAnalytics4GetLinkButton();
    }

    handleGoogleAnalytics4GetLinkButton = () => {
        window.gtag('event', 'Saved_Links_Get_Link', {
            send_to: 'G-9F2DZRE5FG',
            event_category: 'Button',
            event_label: `Saved Links Get Link`,
        });
    }

    displayTableHeader = () => {
        let headers = ["Description", "Brand", "Retailer", "Retail Price", "Est. Comm.", ""];

        return headers.map((header, i) => {
            return <th key={i} style={{ width: header === "Retail Price" ? "150px" : "", maxWidth: i === 0 ? "230px" : i === 1 || i === 2 ? "120px" : "", width: i === headers.length - 1 && this.state.tab === 1 ? "201px" : "", wordWrap: "break-word" }}>{header}{header === "Est. Comm." ? <><small className="d-inline-block ml-1" style={{ position: "relative", top: "-2px" }}>
                <button style={{ cursor: "pointer", outline: "none", border: 0, padding: 0, backgroundColor: "white" }} id="infoIcon"><InformationIcon /></button>
            </small>
                <UncontrolledPopover
                    placement="bottom"
                    target="infoIcon"
                    trigger="hover legacy"
                >
                    <PopoverBody>Commissions are estimated based on factors including retailer rates, pricing, attribution methods (e.g., Mod1 or other), and fees at the time of a consumer's purchase.</PopoverBody>
                </UncontrolledPopover></>

                : null}</th>
        })
    }

    concatMerchantProduct = (product) => {
        if (product.name.toLowerCase().indexOf(product.manufacturer.toLowerCase()) === 0) {
            return product.name.slice(product.manufacturer.length - product.name.length).trim();
        }
        return product.name;
    }

    displayShareModal = () => {
        return (
            <Modal isOpen={this.state.modalOpen} toggleModal={this.toggleProductShareModal}>
                <ShareBlock shareCode={this.state.shareLink}
                    isUserMerchant={this.state.isUserMerchant}
                    title="Product share"
                    customStyle={{ maxWidth: "100%", backgroundColor: "white" }}
                    shareBlockStyle={{ border: "none", backgroundColor: "transparent" }}
                    loggedInUser={this.props.loggedInUser}
                />
            </Modal>
        )
    }

    displayTableRows = () => {
        if (this.state.filteredProductsLinks?.length > 0) {
            return this.state.filteredProductsLinks.map((product, i) => {

                if (product.logoUrl?.length > 0) {
                    return this.displayLinksRows(product, i);
                }

                let getLinkUrl = "https://";
                if (window.location.host.includes("app.rockp") && !!product.shareSubdomain) {
                    getLinkUrl += product.shareSubdomain + ".rockporch.com";
                } else {
                    getLinkUrl += window.location.host;
                }

                getLinkUrl = getLinkUrl + "/prdlink/" + product.shareCode;

                const url = "/Product/ProductPage/" + product.shareCode;
                return (
                    <tr key={i}>
                        <td style={{ maxWidth: "210px", wordWrap: "break-word" }}>
                            <a href={url} target={product.merchant} style={{ cursor: "pointer" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <ImageWrapper src={product.imageUrl} style={{ objectFit: "contain" }} size={"ProductGrid"} />
                                    <h6 className="myproduct-grid-description-ellipsis">{titleCase(this.concatMerchantProduct(product))}{product.sale && !!(product.salePrice) ? <SaleIcon style={{ marginLeft: "3px" }} /> : ""}</h6>
                                </div>
                            </a>
                        </td>


                        <td style={{ maxWidth: "120px", wordWrap: "break-word" }}>
                            <span className="myproduct-grid-description-ellipsis">{product.manufacturer}</span>
                        </td>

                        <td style={{ maxWidth: "120px", wordWrap: "break-word" }}>
                            <span className="myproduct-grid-description-ellipsis">{product.merchant}</span>
                        </td>

                        <td style={{ maxWidth: "150px", wordWrap: "break-word" }}>
                            {product.sale && !!(product.salePrice) ? <span className="mr-1">${product.salePrice.toFixed(2)}</span> : <></>} <span className={`${product.sale && !!(product.salePrice) ? "on-sale-price" : ""}`} style={{ paddingTop: product.sale && !!(product.salePrice) ? "2px" : "" }}>{currencyFormat(product.sale && !!(product.salePrice) && !!(product.originalPrice) ? product.originalPrice : product.price)}</span>
                        </td>

                        <td>
                            <span>{currencyFormat(product.estimatedCommission)}</span>
                        </td>

                        <td>
                            <div className="d-flex align-items-center justify-content-center">
                                <ActionButton onClick={() => this.handleGetLink(getLinkUrl)}
                                    style={{ borderRadius: "3px", bottom: "5px", lineHeight: "16px", fontSize: "14px", height: "30px" }} className="btn btn-outline-brand mr-2">Get Link
                                </ActionButton>

                                <MyProductsItemDropdown
                                    editClick={(e) => {
                                        this.setState({ showEditProduct: !this.state.showEditProduct, productToEditIndex: i });
                                    }}
                                    hideClick={() => this.hide(i)}
                                    toggleShareModal={this.toggleProductShareModal}
                                    product={product}
                                    isUserMerchant={(product.user?.id && this.props.loggedInUser?.id) ? product.user.id === this.props.loggedInUser.id : false}
                                />
                            </div>
                        </td>
                    </tr>
                )
            })
        }
    }

    displayLinksRows = (link, i) => {

        let getLinkUrl = "https://";

        if (window.location.host.includes("app.rockp") && !!link.shareSubdomain) {
            getLinkUrl += link.shareSubdomain + ".rockporch.com";
        } else {
            getLinkUrl += window.location.host;
        }

        getLinkUrl = getLinkUrl + "/mlink/" + link.merchantId + "?userId=" + this.props.loggedInUser.id + "&redirectUrl=" + encodeURIComponent(link.referralUrl);

        return (
            <tr key={i}>
                <td style={{ overflowWrap: "anywhere", maxHeight: "112px" }}>
                    <a href={getLinkUrl} target={link.merchantName} style={{ cursor: "pointer" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <ImageWrapper src={link.logoUrl} style={{ objectFit: "contain" }} />
                            <h6 className="myproduct-grid-description-ellipsis">{link.description?.trim()?.length > 0 ? link.description : getLinkUrl}</h6>
                        </div>
                    </a>
                </td>


                <td>
                    <span className="myproduct-grid-description-ellipsis">{link.brand}</span>
                </td>

                <td>
                    <span className="myproduct-grid-description-ellipsis">{link.merchantName}</span>
                </td>

                <td>
                    <span >{!!(link.price) ? currencyFormat(link.price) : ""}</span>
                </td>

                <td>
                    <span>{!!(link.estimatedCommission) ? currencyFormat(link.estimatedCommission) : ""}</span>
                </td>

                <td>
                    <div className="d-flex align-items-center justify-content-center">

                        <ActionButton onClick={() => this.handleGetLink(getLinkUrl)}
                            style={{ borderRadius: "3px", bottom: "5px", lineHeight: "16px", fontSize: "14px", height: "30px" }} className="btn btn-outline-brand mr-2">Get Link
                        </ActionButton>
                        <LinkItemDropdown
                            toggleShareModal={this.toggleMerchantLinkShareModal}
                            loggedInUser={this.props.loggedInUser}
                            link={link}
                            hideClick={this.hideMerchantLink}
                            editClick={(e) => {
                                this.setState({ showEditLink: !this.state.showEditLink, linkToEdit: link })
                            }}
                        />
                    </div>
                </td>
            </tr>
        )
    }

    handleEditClick = (link) => {
        this.setState({ showEditLink: !this.state.showEditLink, linkToEdit: link })
    }

    toggleMerchantLinkShareModal = (merchantLinkShareLink) => {
        this.setState({ merchantShareModalOpen: !this.state.merchantShareModalOpen, merchantLinkShareLink: this.state.merchantShareModalOpen ? "" : merchantLinkShareLink });
    }

    displayMerchantShareModal = () => {
        let shareProps = {
            shareCode: this.state.merchantLinkShareLink,
            title: "Merchant share",
            customStyle: { maxWidth: "100%", backgroundColor: "white" },
            shareBlockStyle: { border: "none", backgroundColor: "transparent" },
            loggedInUser: this.props.loggedInUser,
            type: "merchant",
        }
        return (
            <Modal isOpen={this.state.merchantShareModalOpen} toggleModal={() => this.toggleMerchantLinkShareModal("")}>
                <ShareBlock {...shareProps}>
                    <MerchantShareBlock
                        {...shareProps}
                    />
                </ShareBlock>
            </Modal>
        )
    }

    convertMerchantLinkToUploadableObject = (link) => {
        return {
            id: link.id,
            merchantId: link.merchantId,
            creatorUserId: link.creatorUserId,
            referralUrl: link.referralUrl,
            description: link.description,
            brand: link.brand ?? null,
            price: link.price ?? null,
            hide: link.hide ?? null
        };
    }

    hideMerchantLink = async (link) => {
        var links = this.state.links.filter(l => l.id !== link.id)
        var filteredProductsLinks = this.state.filteredProductsLinks.filter(l => l.id !== link.id || !(link.logoUrl));
        this.setState({ links, filteredProductsLinks });

        let url = `/Products/AddMerchantLinkData/`;
        let uploadLink = this.convertMerchantLinkToUploadableObject(link);
        uploadLink.hide = true;
        await postRequest(url, uploadLink);
    }

    uploadLink = async (link) => {
        this.setState({ loadingLinkUpload: true })
        let url = `/Products/AddMerchantLinkData/`;
        await postRequest(url, link);
        await this.updateProductsAndLinks();
        this.props.goToSavedLinks();
        this.setState({ loadingLinkUpload: false })
    }

    toggleProductShareModal = async (product, isUserMerchant = false) => {
        let showModal = !this.state.modalOpen;

        if (showModal) {
            let shareLink = await getProductShareLink(product?.shareCode);
            this.setState({ modalOpen: showModal, shareLink, isUserMerchant });
        }
        else {
            this.setState({ modalOpen: showModal, shareLink: "", isUserMerchant });
        }
        
        //this.props.toggleShareModal(shareLink);
    }

    showProductsMobile = () => {
        if (this.state.filteredProductsLinks?.length === 0) {
            return (
                <div className="mt-3">
                    <NoResults />
                </div>
            )
        }

        return this.displayProductsMobile();
    }

    displayProductsMobile = () => {
        return this.state.filteredProductsLinks?.map((product, i) => {
            if (product.logoUrl?.length > 0) {
                return (
                    <LinkRowMobile
                        handleGetLink={this.handleGetLink}
                        link={product}
                        productKey={i}
                        key={i}
                        toggleShareModal={this.toggleMerchantLinkShareModal}
                        loggedInUser={this.props.loggedInUser}
                        hideClick={this.hideMerchantLink}
                        editClick={(e) => {
                            this.setState({ showEditLink: !this.state.showEditLink, linkToEdit: product })
                        }}
                        deviceSize={this.props.deviceSize}
                    />
                )
            }
            return (
                <div key={i}>
                    <ProductRowMobile
                        handleGetLink={this.handleGetLink}
                        getLinkError={this.getLinkError}
                        product={product}
                        productKey={i}
                        key={i}
                        edit={() => this.setState({ showEditProduct: !this.state.showEditProduct, productIndex: i })}
                        hide={() => { this.hide(i) }}
                        toggleProductModal={() => this.props.toggleProductPageModal(product, this.props.loggedInUser, false, () => null)}
                        toggleShareModal={this.toggleProductShareModal}
                        isUserMerchant={(product.user?.id && this.props.loggedInUser?.id) ? product.user.id === this.props.loggedInUser.id : false}
                        editClick={(e) => {
                            this.setState({ showEditProduct: !this.state.showEditProduct, productToEditIndex: i })
                        }}
                        hideClick={() => this.hide(i)}
                        deviceSize={this.props.deviceSize}
                    />
                </div>
            )

        })
    }

    displayLinksMobile = () => {
        return this.state.links?.map((link, i) => {
            return (
                <LinkRowMobile
                    handleGetLink={this.handleGetLink}
                    link={link}
                    productKey={i}
                    key={i}
                    toggleShareModal={this.toggleMerchantLinkShareModal}
                    loggedInUser={this.props.loggedInUser}
                    hideClick={this.hideMerchantLink}
                    editClick={(e) => {
                        this.setState({ showEditLink: !this.state.showEditLink, linkToEdit: link })
                    }}
                    deviceSize={this.props.deviceSize}
                />
            )

        })
    }

    showLinksMobile = () => {
        if (this.state.links?.length === 0) {
            return (
                <div className="mt-3">
                    <NoResults />
                </div>
            ) 
        }
        return (
            this.displayLinksMobile()
        );
    }

    fetchCounts = async (userId) => {
        let productCountUrl = `/Products/GetUserMerchantProductCount?userId=${userId}&onlyPostedProducts=false`
        let productCount = (await getRequest(productCountUrl)).count;

        this.setState({ productCount });
        return productCount;
    }

    fetchProducts = async () => {
        this.setState({ mostRecentSearch: this.state.filter });
        let basePath = this.props.loggedInUser ? "/Products/GetUserMerchantProductsAuthorized" : "/Products/GetUserMerchantProducts";
        let url = `${basePath}?userName=${this.props.loggedInUser.userName}`;
        //let url = `/Products/GetUserMerchantProducts?userName=pterracina&skip=${skip}&take=${take}`;
        let products = await getRequest(url);
        if (products) {
            products.forEach(product => product.user = this.props.loggedInUser);
        }

        return products;
    }

    fetchLinks = async () => {
        let url = "/Products/GetMerchantLinksByUserId?userId=" + this.props.loggedInUser.id;
        let links = await getRequest(url);
        if (links) {
            links.forEach(link => link.user = this.props.loggedInUser);

        }
        return links;
    }


    fetchMoreProducts = async () => {
        this.setState({ loadingMore: true });

        let skip = this.state.products.length;
        let take = 100;

        let basePath = this.props.loggedInUser ? "/Products/GetUserMerchantProductsAuthorized" : "/Products/GetUserMerchantProducts";
        let url = `${basePath}?userName=${this.props.loggedInUser.userName}&skip=${skip}&take=${take}&searchTerm=${this.state.filter}`;
        let products = await getRequest(url);

        if (products === undefined || products === null || products?.length === 0) {
            this.setState({ finishedFetchingProducts: true, loadingMore: false });
        }
        else {
            let newProducts = [...this.state.products, ...products];
            this.setState({ products: newProducts, filteredProducts: newProducts, loadingMore: false, finishedFetchingProducts: products.length < take });
        }
    }

    saveProductSectors = async (productIndex) => {
        let product = this.state.filteredProductsLinks[productIndex];
        let url = `/Products/UpdateUserMerchantProductSectors?userMerchantProductId=${product.userMerchantProductId}`;
        await postRequest(url, product.sectorIds);
    }

    updateProductDescription = async (productIndex, description) => {
        let productId = this.state.filteredProductsLinks[productIndex].userMerchantProductId;
        let product = { ...this.state.filteredProductsLinks[productIndex] };
        product.userDescription = description;
        let url = `/Products/EditUserMerchantProduct/${productId}`;

        let payload = {
            id: product.userMerchantProductId,
            userDescription: product.userDescription,
            merchantProductId: product.merchantProductId,
            coupon: product.shareCode,
            userId: this.props.loggedInUser.id
        }
        await postRequest(url, payload);

    }

    updateProductRating = (productRating) => {
        const { products, filteredProductsLinks } = this.state;
        const productToEditIndex = this.state.productToEditIndex;

        let product = filteredProductsLinks[productToEditIndex];
        product.rating = productRating;

        const updatedProducts = products.map(p =>
            p.userMerchantProductId === product.userMerchantProductId
                ? { ...p, rating: productRating }
                : p
        );

        const updatedProductsLinks = filteredProductsLinks.map(p =>
            !!(p.userMerchantProductId) && p.userMerchantProductId === product.userMerchantProductId
                ? { ...p, rating: productRating }
                : p
        );
        this.setState({ filteredProductsLinks: updatedProductsLinks, products: updatedProducts });
    }


    updateProductSectors = async (productIndex, sectors) => {
        let filteredProductsLinks = [...this.state.filteredProductsLinks];
        let product = filteredProductsLinks[productIndex];

        if (!!(product)) {
            product.sectorIds = sectors;
            filteredProductsLinks[productIndex] = product;

            this.setState({ filteredProductsLinks });
        }
    }

    onInputChange = (e, reset = false) => {
        let filter = "";

        if (reset) {
            filter = e;
        } else {
            filter = e.target.value;
        }

        this.setState({ filter });
    };

    onInputKeydown = (e) => {
        if (e.keyCode === 13) {
            this.updateProducts();
        }
    }

    resetFilter = () => {
        if (this.state.filter !== "") {
            const filter = "";
            this.setState({ filter });

            this.setState({ reset: this.state.reset + 1 });
            if (this.state.tab === 1) {
                this.setState({ filter: "", filteredLinks: this.state.links });
            }
        }
    }

    displayGetLinkModal = () => {
        return (
            <Modal isOpen={this.state.getLinkPopover}
                showHeader={false} toggleModal={() => this.setState({ getLinkPopover: !this.state.getLinkPopover })}
            >
                <p className="text-center mx-auto px-2 pt-5 pb-4">Your <strong>product link has been copied to clipboard</strong> and is ready to be included in your post</p>

            </Modal>
        )
    }

    onTabChange = (tab) => {
        this.setState({ tab });
        this.props.setProductsTab(tab);
    }

    submitRating = async (productRating, product) => {
        if (productRating > 0) {
            this.updateProductRating(productRating);

            let url = "/Products/Rate?id=" + product.userMerchantProductId + "&rating=" + productRating;
            let rating = await getRequest(url);
        }
    }

    render() {
        let breadCrumbList = [{ link: '/Account/CreatorDashboard', text: 'Creator Dashboard' }, { link: '/Account/Products', text: 'My Products', active: true }];
        let smallSizes = ["sm", "md"];
        let { deviceSize } = this.props;
        let { productToEditIndex } = this.state;

        return (
            <div style={{ marginLeft: this.props.creatorDashboard && smallSizes ? "-13px" : "", marginRight: this.props.creatorDashboard && smallSizes ? "-13px" : "" }}>
                <Helmet>
                    <title>RockPorch - My Products</title>
                </Helmet>
                <Toaster />
                {
                    this.state.products != undefined && this.state.products?.length > 0 ? (
                        <EditProductModal
                            showModal={this.state.showEditProduct}
                            toggleModal={() => this.setState({ showEditProduct: false })}
                            product={this.state.filteredProductsLinks[this.state.productToEditIndex]}
                            productDescription={this.state.filteredProductsLinks[this.state.productToEditIndex]?.userDescription}
                            saveDescription={(description) => this.updateProductDescription(productToEditIndex, description)}
                            updateSectors={(sectors) => this.updateProductSectors(this.state.productToEditIndex, sectors)}
                            sectors={this.state.sectors}
                            saveSectorChanges={() => this.saveProductSectors(productToEditIndex)}
                            updateProductRating={(rating) => this.updateProductRating(productToEditIndex, rating)}
                            submitRating={this.submitRating}
                            updateProductsAndLinks={this.updateProductsAndLinks}
                            setLoading={this.setLoading}
                            goToSavedLinks={this.props.goToSavedLinks}
                        />  
                    ): null
                }

                {
                    !!(this.state.linkToEdit) && this.state.showEditLink ?(
                            <EditLinkModal
                                showModal={this.state.showEditLink}
                                toggleModal={() => this.setState({ showEditLink: false, linkToEdit: null })}
                                loadingLinkUpload={this.state.loadingLinkUpload}
                                uploadLink={this.uploadLink}
                                link={this.state.linkToEdit}
                                convertMerchantLinkToUploadableObject={this.convertMerchantLinkToUploadableObject}
                                />
                        ) : null
                }

                {
                    this.displayShareModal()
                }

                {
                    this.displayMerchantShareModal()
                }

                <section style={{ display: this.props.creatorDashboard ? "none" : "" }}>
                    <AccountBanner breadCrumbList={breadCrumbList}>
                        <h1>Saved Links</h1>
                        <p>Manage commissionable links for your social posts</p>
                    </AccountBanner>
                </section>

                <section id="myProducts" className={`${this.props.creatorDashboard ? "" : "gray-bg"} ${this.props.creatorDashboard ? "" : "full-height"}`}>
                    {this.displayGetLinkModal()}

                    {
                        smallSizes.includes(deviceSize) ? (
                            <>
                                <div className="ml-3 pt-2">
                                    <small>
                                        Estimated Commission
                                        <sup>*</sup>
                                        <Link to="#" onClick={e => e.preventDefault()} style={{ position: "relative", fontSize: "8px", backgroundColor: "transparent", border: "0px solid transparent" }} id="estimatedCommissionsHeader">
                                            <InformationIcon style={{ position: "absolute", left: "4px" }} x="15" y="15" />
                                        </Link>
                                    </small>
                                    <UncontrolledPopover trigger="hover legacy" target="estimatedCommissionsHeader" placement="top">
                                        <PopoverBody>
                                            Commissions are estimated based on factors including retailer rates, pricing, attribution methods (e.g., Mod1 or other), and fees at the time of consumer's purchase
                                        </PopoverBody>
                                    </UncontrolledPopover>
                                </div>
                            </>

                        ) : <></>}
                    <div className="container pt-0 pb-5" style={{ marginTop: "-10px" }}>
                        <div className={`search-interest pt-4 mx-auto position-relative`} style={{ top: smallSizes.includes(deviceSize) ? "" : "-30px" }}>
                            <div className={`search-block ${this.props.creatorDashboard && !smallSizes.includes(deviceSize) ? "d-flex justify-content-between" : ""}`}>
                                <div className={`form-group position-relative ${smallSizes.includes(deviceSize) ? "mx-auto" : "mb-3"} `} style={{ display: this.props.creatorDashboard ? "" : "", maxWidth: !smallSizes.includes(deviceSize) ? "" : "100%" }}>
                                    <input type="search" id="gsearch" name="gsearch" placeholder={`Search Links...`} value={this.state.filter} onChange={(e) => !this.state.loading ? this.onInputChange(e) : () => { }} onKeyDown={!this.state.loading ? this.onInputKeydown : () => { } }/>
                                    <input type="submit" className="position-absolute" onClick={!this.state.loading ? () => this.setState({ searchIcon: this.state.searchIcon + 1 }) : () => { }} />
                                    <button className="close browse-close mr-2 position-absolute" style={{ right: "3%", bottom: "15px" }} onClick={this.resetFilter} >
                                        <CloseIcon />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="row mx-0 my-0" style={{ minHeight: "400px", display: !this.state.loading && this.props.creatorDashboard && this.state.filteredProductsLinks?.length === 0 ? "none" : "" }}>
                            <div className="col-12 px-0">

                                {this.state.loading ?
                                    <LoadSpinner />
                                    :
                                    <></>
                                }

                                {
                                    this.state.addingProduct ?
                                        <LoadSpinnerOverlay />
                                        : <></>
                                }

                                {
                                    smallSizes.includes(deviceSize) &&  !this.state.loading ? (
                                        <>
                                            {this.showProductsMobile()}
                                        </>

                                    ) 

                                    : !this.state.loading ? (

                                        <div className="table-responsive product-table" style={{ overflow: "visible" }}>
                                            <table className="table" style={{ border: 0 }}>
                                                <thead>
                                                    <tr>
                                                        {this.displayTableHeader()}
                                                    </tr>
                                                </thead>

                                                
                                                <tbody>
                                                    {this.displayTableRows()}
                                                </tbody>
                                            </table>
                                        </div>
                                       
                                    ) : <></>
                                }

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

function mapStateToProps(storeState,ownProps) {
    return {interactionState:storeState.interactionReducer}
}


export default connect(mapStateToProps, { toggleProductPageModal,toggleShareModal })(MyProducts);