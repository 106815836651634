import React from "react";
import BlueTickIcon from "./icons/BlueTickIcon";
import { Link } from "react-router-dom";
import DummyProfilePicture from "components/DummyProfilePicture";
import ImageWrapper from "components/ImageWrapper";

/**
 * 
 * @param {{
 * user:object,
 * followAction:function, 
 * messageAction:function, 
 * loggedInUser:object
 * isFollowing:boolean
 * tags:[]
 * }} props 
 */

const ProfileInfoCard = (props) => {
    let renderFollowButton = () => {
        if (props.loggedInUser && props.followAction && props.user.id !== props.loggedInUser.id) {
            if (props.isFollowing) {
                return (
                    <button className="btn btn-primary d-flex justify-content-center" onClick={props.followAction}>Unfollow -</button>
                )
            } else {
                return (
                    <button className="btn btn-primary d-flex justify-content-center" onClick={props.followAction}>Follow +</button>
                )
            }
        }
    }

    let renderMessageButton = () => {
        if (props.loggedInUser && props.messageAction && props.loggedInUser.id !== props.user.id) {
            return (
                <button className="btn btn-secondary" onClick={props.messageAction}>
                    Message
                </button>
            )
        }
    }

    if (props.noDiv) {
        return (
            <>
                <Link to={"/Profile/" + props.user?.userName}>
                    {
                        props.user?.imageUrl ? <ImageWrapper size={"Profile"} src={props.user.imageUrl} alt="Avatar" className="avatar mb-3" /> : <ImageWrapper src="/images/avatar2.svg" alt="Avatar" className="avatar mb-3" />
                    }
                    <h4 className="d-flex align-items-center">
                        {props.user?.userName}
                    </h4>
                </Link>

                <div className="pt-2">{props.user?.tagLine}</div>

                <div className="btn-block d-flex mt-3">
                    {renderFollowButton()}
                    {renderMessageButton()}
                </div>
            </>
        )
    }

    return (
        <div className="col-md-4 user-info mb-5 mb-md-0 px-md-5">
            <Link to={"/Profile/" + props.user?.userName}>
                {
                    props.user?.imageUrl ? <ImageWrapper src={props.user.imageUrl} alt="Avatar" className="avatar mb-3" /> : <ImageWrapper src="/images/avatar2.svg" alt="Avatar" className="avatar mb-3" />
                }
                <h4 className="d-flex align-items-center">
                    {props.user?.userName}
                </h4>
            </Link>

            <div className="pt-2">{props.user?.tagLine}</div>

            <div className="btn-block d-flex mt-3">
                {renderFollowButton()}
                {renderMessageButton()}
            </div>
        </div>
    )
}

export default ProfileInfoCard;

