import * as React from "react";
import { withRouter } from 'react-router-dom';
import ImageWrapper from "components/ImageWrapper";
import ActionButton from "components/ActionButton";
import PenIcon from "components/icons/PenIcon";
import { postRequest } from "sharedUtils/httpUtils";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";

class CreatorDashboardHeader extends React.Component {

    state = {
        loggedInUser: null,
        profilePicUpdate: false,
        profilePicUploadError: false,
        uploadingImage: false,
    }

    componentDidMount = async () => {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);

        if (!!(this.props.loggedInUser)) {
            this.setState({ loggedInUser: this.props.loggedInUser })
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.loggedInUser !== this.props.loggedInUser) {
            this.setState({ loggedInUser: this.props.loggedInUser })
        }
    }

    setSelectedFile = async (e) => {
        e.preventDefault();

        if (e.target.files?.length > 0 && e.target.files[0].type?.startsWith('image/')) {
            const file = e.target.files[0];

            const loggedInUser = { ...this.state.loggedInUser }
            loggedInUser.imageUrl = URL.createObjectURL(file);

            this.setState({ uploadingImage: true, loggedInUser });
            
            const formData = new FormData();
            formData.append('File', file);

            let uploadProfilePicUrl = '/api/Account/UploadProfilePic';

            postRequest(uploadProfilePicUrl, formData, null, false)
                .then((user) => {
                    
                    this.props.setLoggedInUser(user);

                    this.setState({ profilePicUpdated: true })
                    setTimeout(() => this.setState({ profilePicUpdated: false }), 3000);
                })
                .catch((error) => {
                    console.error("Error uploading profile pic:", error);
                });

            this.setState({ uploadProfilePicUrl: false })
            e.target.value = null;
        } else {
            this.setState({ profilePicUploadError: true })
        }
    };

    onClickImageUpload = (event) => {
        event.preventDefault();
        document.getElementById("profilePicInput").click();
    };


    render() {
        const imageUrl = this.state.loggedInUser?.imageUrl;
        const userName = this.state.loggedInUser?.userName;

        const avatarStyle = this.props.deviceSize === "sm" ? { width: "75px", height: "75px" } : {};
        return (
            <section>
                <input
                    hidden={true}
                    id="profilePicInput"
                    type="file"
                    accept="image/*"
                    value={""}
                    onChange={(e) => { this.setSelectedFile(e); }}
                />
                <div className="d-flex justify-content-between align-items-center">
                    <div className="m-0 user-activity align-items-center">

                        <div className={`text-left user-activity-info user-activity-creator-dashboard mt-3`}>
                            <div className="d-flex flex-wrap align-items-center py-0 px-0" style={{ minWidth: "190px" }}>
                                <div className="d-flex align-items-baseline">
                                    {imageUrl?.length > 0 ? < ImageWrapper src={imageUrl} size="Profile" alt="" /> : < ImageWrapper src="/images/avatar2.svg" alt="" style={avatarStyle} />}
                                    <PenIcon id={`changeProfilePic`} onClick={(e) => { this.onClickImageUpload(e) }} style={{ position: "relative", left: "-5px", cursor: "pointer", zIndex: 99 }} />
                                    <Popover trigger="click" toggle={() => this.setState({ profilePicUploadError: false })} placement="left" isOpen={this.state.profilePicUploadError} target="changeProfilePic">
                                        <PopoverBody>
                                            Make sure to select a single image file
                                        </PopoverBody>
                                    </Popover>
                                    <Popover
                                        placement="right"
                                        target="changeProfilePic"
                                        isOpen={this.state.profilePicUpdated}
                                        toggle={() => this.setState({ profilePicUpdated: false })}
                                    >
                                        <PopoverHeader>Profile Picture Updated</PopoverHeader>
                                        {/*<PopoverBody>*/}
                                        {/*    <div style={{ cursor: "pointer" }} onClick={async (e) => {*/}
                                        {/*        this.props.history.push(`/Profile/${props.loggedInUser.userName}`);*/}
                                        {/*    }}>*/}
                                        {/*        <small>Go to profile</small></div>*/}
                                        {/*    <div style={{ cursor: "pointer" }} onClick={e => {*/}
                                        {/*        this.setDefaultProfilePicture();*/}
                                        {/*    }}>*/}
                                        {/*    </div>*/}
                                        {/*</PopoverBody>*/}

                                    </Popover>
                                </div>
                                <div className="px-3">
                                    {
                                        this.props.deviceSize === "sm" ?
                                            <h5 style={{ fontSize: "1.09rem" }}>{userName}</h5>

                                            :
                                            <h4>{userName}</h4>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.props.deviceSize !== "sm" ?
                            <div className="position-relative" style={{ top: this.props.deviceSize === "sm" ? "4px" : "5px" }}>
                                <ActionButton onClick={this.props.renderCalendly} style={{ borderRadius: "3px", bottom: "5px", fontSize: this.props.deviceSize === "sm" ? "11px" : "", padding: this.props.deviceSize === "sm" ? "2px 5px" : "" }} className="btn btn-outline-brand">Schedule a Strategy Call</ActionButton>
                            </div>                            :
                            <></>

                    }
                    {/*<div className={`mb-4 d-flex ${["sm"].includes(this.props.deviceSize) ? "flex-column" : "justify-content-end align-items-end"}`}>*/}
                    {/*    <ActionButton onClick={this.props.renderCalendly} style={{ borderRadius: "3px", bottom: "5px" }} className="btn btn-outline-primary">Schedule a Strategy Call</ActionButton>*/}
                    {/*</div>*/}
                </div>
                {
                    this.props.deviceSize === "sm" ?
                        <div className="mt-3 d-flex justify-content-end" style={{  }}>
                            <ActionButton onClick={this.props.renderCalendly} style={{ borderRadius: "3px", bottom: "5px", fontSize: this.props.deviceSize === "sm" ? "11px" : "", padding: this.props.deviceSize === "sm" ? "2px 5px" : "" }} className="btn btn-outline-brand">Schedule a Strategy Call</ActionButton>
                        </div> :
                        <></>

                }
            </section>
        );

    }

};

export default withRouter(CreatorDashboardHeader);