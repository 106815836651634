import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import ImageWrapper from "components/ImageWrapper";
import DummyProfilePicture from "components/DummyProfilePicture";
import { NavLink } from "react-router-dom";
import ThreeDotsHorizontalIcon from "components/icons/ThreeDotsHorizontalIcon";
import ShareBar from "components/Share/ShareBar";
import { connect } from "react-redux";
import { toggleShareModal, toggleProductPageModal } from "stateManagement/reducers/modalReducer";
import { useHistory } from "react-router-dom";
import UserTileGeneral from "components/UserTileGeneral";
import { getProductShareLink } from "../../sharedUtils/shareLinkPrefixes"
import PlusIcon from "components/icons/PlusIcon";
import Tick from "components/icons/Tick";
import BookmarkIcon from "../icons/BookmarkIcon";
import ShareIosIcon from "../icons/ShareIosIcon";
import InformationIcon from "../icons/InformationIcon";
import SaleIcon from "../icons/SaleIcon";
import ThumbsUpIcon from "../../components/icons/ThumbsUpGeneric";

import RatingIcon from "components/icons/RatingIcon";
import RatingIconHalf from "components/icons/RatingIconHalf";
import RatingIconEmpty from "components/icons/RatingIconEmpty";
import StarRatingComponent from 'react-star-rating-component';

import Modal from "components/Modal";
import ProductReviewUsersList from "components/ProductReviewUsers/ProductReviewUsersList";


/**
 * 
 * @param {{product:object, redirectUrl:string, likeProduct:Function, saveProduct:Function,likedByUser:boolean,savedByUser:boolean,likeCount:Number, addToInventory:Function}} props
 */
const ProductPost = (props) => {
    const { userMerchantProductId, shareCode, imageUrl, name, description, price, likes, rating, ratingCount, user } = props.product;
    const { likeCount, likedByUser, savedByUser } = props;
    const [showFullContent, setShowFullContent] = useState(false);
    const [shareLink, setShareLink] = useState("");
    const coupon = shareCode;

    const collapsedContentStyle = { textAlign: "justify", marginTop: "0em", maxHeight: "3em", textOverflow: "ellipsis", overflow: "hidden" };
    const notOverflowedContentStyle = { textAlign: "justify", marginTop: "0em", marginBottom: "0.5em", maxHeight: "3em", textOverflow: "ellipsis", overflow: "hidden" };
    const expandedContentStyle = { textAlign: "justify", marginTop: "0em" };

    const [contentOverflowed, setContentOverflowed] = useState(false);
    const [contentStyle, setContentStyle] = useState(notOverflowedContentStyle);
    const [collapsed, setCollapsed] = useState(true);

    const [showAddedToInventoryPrompt, setAddedPrompt] = useState(false);

    const [showUserReviewList, setShowReviewList] = useState(false);
    const [ratingsUserMerchantProductId, setRatingUserMerchantProduct] = useState(0);

    const contentRef = React.createRef();

    const history = useHistory();

    useEffect(() => {
        async function getShareLink() {
            let shareLink = await getProductShareLink(props.product?.shareCode);
            setShareLink(shareLink);
        };
        getShareLink();

        let contentOverflowed = contentRef?.current?.offsetHeight < contentRef?.current?.scrollHeight || contentRef?.current?.offsetWidth < contentRef?.current?.scrollWidth;
        if (contentOverflowed) {
            setContentOverflowed(contentOverflowed);
            setContentStyle(collapsedContentStyle);
        }
    }, []);

    function cropText(text) {
        if (text !== undefined) {
            if (!contentOverflowed) {
                return <div className="content px-1" style={contentStyle} ref={contentRef}>
                    {text}
                </div>
            } else {
                if (collapsed) {
                    return (<>
                        <div className="content px-1" style={contentStyle} ref={contentRef}>
                            {text}
                        </div>

                        <div className="content px-1" style={{ textAlign: "right" }}>
                            <Link to="#" onClick={(e) => { e.preventDefault(); setContentStyle(expandedContentStyle); setCollapsed(false) }}>
                                see more
                            </Link>
                        </div>
                    </>);
                } else {
                    return (<>
                        <div className="content px-1" style={contentStyle} ref={contentRef}>

                            {text}
                        </div>
                        <div className="content px-1" style={{ textAlign: "right" }}>
                            <Link to="#" onClick={(e) => { e.preventDefault(); setContentStyle(collapsedContentStyle); setCollapsed(true) }}>
                                see less
                            </Link>
                        </div>
                    </>);
                }
            }
        }

        let customStyle = { textAlign: "justify", marginTop: "1em" }
        let buttonStyle = { backgroundColor: "transparent", fontSize: "xx-small", border: "0px solid transparent" }
        if (text?.length < 240) {
            return <p style={customStyle}> {text}</p>
        }
        else if (text !== undefined && showFullContent === true) {
            return (
                <p style={customStyle}>
                    {text}
                    <button className="btn btn-light btn-sm" style={buttonStyle} onClick={() => setShowFullContent(false)}>
                        ...hide
                    </button>
                </p>
            )
        }
        else if (text !== undefined && showFullContent === false) {
            return (
                <p style={customStyle}>
                    {text.slice(0, 240)}
                    <button className="btn btn-light btn-sm" style={buttonStyle} onClick={() => setShowFullContent(true)}>
                        ...see more
                    </button>
                </p>
            )
        }
    }

    let renderBookmarkOverlay = () => {
        return (<span className="bookmark-overlay position-absolute" onClick={(e) => {
            if (props.loggedInUser) {
                e.preventDefault()
                props.savePost(shareCode);
            } else {
                props.toggleSignUpModal();
            }

        }} style={{ cursor: "pointer", left: "63px" }}>            
            <BookmarkIcon height="16" width="16" fill={props.product.savedByUser === true ? "#e55934" : "none"} />
        </span>);
    }

    let renderShareOverlay = () => {
        return (<span className="share-overlay position-absolute" onClick={() => {
            props.toggleShareModal(shareLink)
        }} style={{ cursor: "pointer", left: "95px" }}>            
            <ShareIosIcon color="#E55934" />
        </span>);
    }

    let renderInfoOverlay = () => {
        return (<span className="info-overlay position-absolute" onClick={() => {
            props.toggleProductPageModal(props.product, props.loggedInUser, false, () => null, { type: null, shareCode: null, parentState: null }, false, !!(props.isSearch))
        }} style={{ cursor: "pointer" }}>
            <InformationIcon fill="#E55934"/>
        </span>);
    }

    let renderAddProductOverlay = () => {
        if (props.loggedInUser != null && (props.loggedInUser.proStatusId === 1) && !(props.product.approvedCreatorsOnly)) {
            if (props.inUserInventory !== true && showAddedToInventoryPrompt === false) {
                return (
                    <span style={{ left: "126px" }} className="inventory-overlay position-absolute" onClick={e => {
                        e.preventDefault();
                        setAddedPrompt(true);

                        props.addToInventory(props.product.merchantProductId);
                    }}>
                        <PlusIcon height={15} width={15} fill="#e55934" fillOpacity="0.54"></PlusIcon>
                    </span>
                )
            } else if (showAddedToInventoryPrompt === true || props.inUserInventory === true) {
                return (
                    <span style={{ left: "126px" }} className="inventory-overlay position-absolute" onClick={e =>
                        e.preventDefault()} >
                        <Tick fill="#e55934" height="12" width={14} fillOpacity="0.54" />
                    </span>
                )
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
    }

    const renderSaleOverlay = (sale) => {
        if (sale) {
            return (
                <span className="on-sale-product-post-single">
                    <SaleIcon width="28" height="28"/>
                </span >
            );
        }
    }


    function displayAddProduct() {
        if (props.loggedInUser != null && (props.loggedInUser.proStatusId === 1)) {
            if (props.inUserInventory !== true && showAddedToInventoryPrompt === false) {
                return (
                    <Link to="/" className="d-inline-flex align-items-center" onClick={e => {
                        e.preventDefault();
                        setAddedPrompt(true);

                        //setTimeout(() => setAddedPrompt(false),2500)

                        props.addToInventory(props.product.merchantProductId);


                    }}>
                        <PlusIcon height={15} fill="rgba(0,0,0,0.6)"></PlusIcon>
                        <span className="sharebar-text">Add to Inventory</span>
                    </Link>
                )
            }
            else if (showAddedToInventoryPrompt === true || props.inUserInventory === true) {
                return (
                    <Link to="#" onClick={e => e.preventDefault()} className="d-inline-flex align-items-center" style={{ pointerEvents: "none" }}>
                        <Tick fill="#e55934" height="16" fillOpacity="0.54" />
                        <span className="sharebar-text">In Inventory</span>
                    </Link>

                )
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
    }

    const renderRatingsModal = ratingsUserMerchantProductId => {
        if (props.loggedInUser) {
            setRatingUserMerchantProduct(ratingsUserMerchantProductId);
            setShowReviewList(true);
        }
        else {
            props.toggleSignUpModal();
        }
    }

    const toTitleCase = (string) => {
        return string.toLowerCase().replace(/(?:^|\s|-)\w/g, function (match) {
            return match.toUpperCase();
        });
    }   

    const renderProductDetails = () => {
        return (

            <div className="d-flex flex-column py-0 px-1 position-absolute" style={{ bottom: "8px", width: "100%", background: "white", borderRadius: "0px 0px 15px 15px" }}>
                <div className="d-flex align-items-end">
                    <div className="ml-1" style={{ marginBottom: "-16px" }}>
                        <StarRatingComponent
                            name="starRating"
                            starCount={5}
                            value={props.product?.rating}
                            renderStarIcon={(index, value) => {
                                if (index <= value) {
                                    return (<span className="ratingIcon" key={index}><RatingIcon height={["sm"].includes(props.deviceSize) ? 13 : 16} width={["sm"].includes(props.deviceSize) ? 13 : 16} /></span>);
                                } else {
                                    return (<span className="ratingIcon" key={index}><RatingIconEmpty height={["sm"].includes(props.deviceSize) ? 13 : 16} width={["sm"].includes(props.deviceSize) ? 13 : 16} /></span>);
                                }
                            }}
                            renderStarIconHalf={(index, value) => {
                                return (<span className="ratingIcon" key={index}><RatingIconHalf height={["sm"].includes(props.deviceSize) ? 13 : 16} width={["sm"].includes(props.deviceSize) ? 13 : 16} /></span>);
                            }}
                            onStarClick={() => {
                                if (props.product?.ratingCount > 0) {
                                    renderRatingsModal(props.product.userMerchantProductId)
                                }
                            }}
                        />
                    </div>
                    <span style={{ color: "#e55934", marginLeft: "3px", fontSize: "12px" }}>
                        <Link to="/" onClick={e => {
                            e.preventDefault();
                            renderRatingsModal(props.product.userMerchantProductId);
                        }}>
                            {props.product?.ratingCount > 0 ? `(${props.product?.ratingCount})` : null}
                        </Link>
                    </span>
                </div>

                <div className="users user-tile-general py-1 ml-2" style={{ whiteSpace: "nowrap" }}>
                    <h5 style={{ marginBottom: "0px" }}><div style={{ maxWidth: "1px" }}>{props.product.manufacturer?.toLowerCase().includes("rcktemp") ? props.product.merchant : props.product.manufacturer}{props.product.sale ? <SaleIcon style={{ marginLeft: "3px" }} /> : ""}</div></h5>
                </div>
                {/*<div className="users user-tile-general py-1 ml-2" style={{ whiteSpace: "nowrap" }}>*/}
                {/*    <h5 style={{ marginBottom: "0px" }}><div style={{ maxWidth: "1px" }}>{props.product.name.toUpperCase()}</div></h5>*/}
                {/*</div>*/}
                <div className="users user-tile-general py-1 ml-2" style={{ width: "95%", whiteSpace: "nowrap" }}>
                    <h5 style={{ marginBottom: "0px" }}>
                        <div style={{ display: "table", tableLayout: "fixed", width: "100%" }}>
                            <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "table-cell" }}>
                                {toTitleCase(props.product.name)}
                            </div>
                        </div>
                    </h5>
                </div>
                <div className={`users user-tile-general py-1 ml-${props.product.salePrice ? "2" : "1"}`} style={{ whiteSpace: "nowrap" }}>
                    <h5 style={{ marginBottom: "0px" }}><div className="d-flex" style={{ maxWidth: "1px" }}><div className="mr-1" >{props.product.sale && !!(props.product.salePrice) ? "$" + props.product.salePrice.toFixed(2) : <></>}</div><div className={`${props.product.sale && !!(props.product.salePrice) ? "on-sale-price" : ""}`}>${props.product.sale && !!(props.product.salePrice) && !!(props.product.originalPrice) ? props.product.originalPrice.toFixed(2) : props.product.price.toFixed(2)}</div></div></h5>
                </div>
            </div>
        )
    }

    const displayUserReviewList = () => {
        return (
            <Modal isOpen={showUserReviewList}
                showHeader={false}
                toggleModal={() => setShowReviewList(!showUserReviewList)}
            >
                <ProductReviewUsersList merchantProductId={ratingsUserMerchantProductId} loggedInUser={props.loggedInUser} deviceSize={props.deviceSize} />

            </Modal>
        )
    }


    const url = "/Product/ProductPage/" + props.product.shareCode + "?r=1";

    return (
        <div className="activity-info-block" style={{ borderRadius: "20px" }}>
            {displayUserReviewList()}
            <div className="d-flex flex-wrap justify-content-between align-items-center py-0 px-2">
                <UserTileGeneral className="py-2 ml-2" user={user} subtitle={" "} showCheckMark={false} />
            </div>

            <div className="product-image-gallery image-block position-relative">
                {renderBookmarkOverlay()}
                {renderShareOverlay()}
                {renderAddProductOverlay()}
                {renderInfoOverlay()}
                {props.showRecommendations ? props.renderThumbsUpOverlay(props.product, true) : <></>}
                {renderSaleOverlay(props.product.sale)}
                <a href={url} target={props.product.merchant} style={{ cursor: "pointer" }}>
                    <ImageWrapper
                        className="product-post-image img-fluid"
                        alt=""
                        src={imageUrl}
                        size={"Product"}
                        lazy={!!props.aboveTheFold ? !props.aboveTheFold : true}
                     />
                </a>
                {props.displayStarRatings ? renderProductDetails() : <></>}
            </div>

            <div className="feed-detail px-1 pt-1 pb-0">

            {
                description !== undefined ? (
                    <div className="text-center pt-2 pb-2 mb-0">
                        {cropText(description)}
                    </div>
                ) : null
            }

            </div>

        </div>

    )

}



export default connect(null, { toggleShareModal, toggleProductPageModal })(ProductPost);