import React, { Component } from "react";
import _ from "lodash";
import TextField from '@material-ui/core/TextField';
import AnalyticsByProductTable from "components/AnalyticsByProductTable";
import AnalyticsByMerchantTable from "components/AnalyticsByMerchantTable";
import AnalyticsByCommissionDetailItemTable from "components/AnalyticsByCommissionDetailItemTable";
import AnalyticsPerformanceByHour from "components/AnalyticsPerformanceByHour";
import AnalyticsPerformanceByDay from "components/AnalyticsPerformanceByDay";

import Select from 'react-select';

const { DateTime } = require("luxon");


class AnalyticsByPerformance extends Component {
    constructor(props) {
        super(props);

        this.tabSelectOptions = {
            product: {
                value: 1,
                label: "Performance by Link",
            },
            commissionDetailItem: {
                value: 2,
                label: "Performance by Product"
            },
            merchant: {
                value: 3,
                label: "Performance by Brand"
            },
            hour: {
                value: 4,
                label: "Performance by Hour"
            },
            day: {
                value: 5,
                label: "Performance by Day"
            },
        } 

        this.state = {
            tab: 1,
            tabSelectOptions: {
                options: [
                    this.tabSelectOptions.product,
                    this.tabSelectOptions.commissionDetailItem,
                    this.tabSelectOptions.merchant,
                    this.tabSelectOptions.hour,
                    this.tabSelectOptions.day,
                ],
                defaultValue: this.tabSelectOptions.product,
                currentValue: this.tabSelectOptions.product,
            },
        };
    }

    componentDidMount = async () => {
        await this.props.getAnalytics(this.props.startDate, this.props.endDate);
        await this.props.clearPerformance();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate) {
              if (this.props.startDate <= this.props.endDate) {
                this.setState({ dateError: false });
                  if (this.state.tab === 1) {
                      this.props.getAnalytics();
                  } else if (this.state.tab === 2) {
                      this.props.getCommissionDetailItemsAnalytics();
                  } else {
                      this.props.getMerchantAnalytics();
                  } 
            } else {
                this.setState({ dateError: true });
            }
        }
        if (prevState.tab !== this.state.tab) {
            if (this.state.tab === 1) {
                this.props.getAnalytics(this.props.startDate, this.props.endDate);
            } else {
                this.props.getMerchantAnalytics(this.props.startDate, this.props.endDate);
            }
        }
        
    }

    handleTabSortSelect = async (e) => {
        const tab = e.value;
        let tabSelectOptions = {...this.state.tabSelectOptions};
        tabSelectOptions.currentValue = e;
        this.props.resetPerformanceTabs();
        this.props.clearPerformance();
        this.setState({ tab, tabSelectOptions });
    }

    handleSortSelect = async (e) => {
        await this.props.handleSortSelect(e);
    }

    handleMerchantSortSelect = async (e) => {
        await this.props.handleMerchantSortSelect(e);
    }

    render() {
        let showNA = this.props.analytics?.some(obj => obj.sourceDataAvailable === false);

        return (
            <div className="container pt-0 pb-5 px-0 px-md-3" style={{ display: this.props.display ? "" : "none", fontFamily: "'Archivo', sans-serif" }}>
                <div className={`${this.props.deviceSize === "sm" ? "pt-2" : ""}`}>
                    <div className="search-block">
                        <div className={`mx-0 d-flex justify-content-between ${["sm"].includes(this.props.deviceSize) ? "ml-2 mr-2" : ""}`}>
                            <div className="" style={{ position: "relative", top: "0", width: "250px" }}>
                                <Select
                                    isMulti={false}
                                    isClearable={false}
                                    isSearchable={false}
                                    blurInputOnSelect={true}
                                    captureMenuScroll={true}
                                    value={this.state.tabSelectOptions.currentValue}
                                    onChange={(e) => { this.handleTabSortSelect(e) }}
                                    options={this.state.tabSelectOptions.options} />
                            </div>
                        </div>
                        {
                            this.state.tab === 1 ?
                                <>
                                    <h6 className={`mt-3 ${["sm"].includes(this.props.deviceSize) ? "ml-2 mr-2" : ""}`}> Learn which links perform well for you by Clicks, Sales, and Conversions</h6>
                                    <p className={`mt-2 ${["sm"].includes(this.props.deviceSize) ? "ml-2 mr-2" : ""}`} style={{ display: showNA ? "" : "none", fontSize: "13px" }}>* Source data not available for this brand. Check Performance by brand for specific data available for this brand.</p>
                                </>
                                :
                                this.state.tab === 2 ?
                                    <h6 className={`mt-3 ${["sm"].includes(this.props.deviceSize) ? "ml-2 mr-2" : ""}`}> Learn which products are your best sellers</h6>
                                    :
                                    this.state.tab === 3 ?
                                        <h6 className={`mt-3 ${["sm"].includes(this.props.deviceSize) ? "ml-2 mr-2" : ""}`}> Learn which brands perform well for you by Clicks, Sales, and Conversions</h6>
                                        :
                                        this.state.tab === 4 ?
                                            <h6 className={`mt-3 ${["sm"].includes(this.props.deviceSize) ? "ml-2 mr-2" : ""}`}> Learn which hours of the day perform well for you by Clicks and Sales</h6>
                                            :
                                            this.state.tab === 5 ?
                                                <h6 className={`mt-3 ${["sm"].includes(this.props.deviceSize) ? "ml-2 mr-2" : ""}`}> Learn which days of the week perform well for you by Clicks and Sales</h6>
                                                :
                                     <></>
                        }
                    </div>
                </div>
                {
                    this.state.tab === 1 ?
                        <AnalyticsByProductTable
                            loggedInUser={this.props.loggedInUser}
                            analytics={this.props.analytics}
                            analyticsLoading={this.props.analyticsLoading}
                            getAnalytics={this.props.getAnalytics}
                            date={this.props.date}
                            deviceSize={this.props.deviceSize}
                            handleGetLink={this.props.handleGetLink}
                            handleSortSelect={this.props.handleSortSelect}
                            selectOptions={this.props.selectOptions}
                            sortDescending={this.props.sortDescending}
                            resetPerformanceTabs={this.props.resetPerformanceTabs}
                            userChanged={this.props.productUserChanged ?? false}
                            aggregateChanged={this.props.aggregateChanged}
                            display={this.props.display}
                            browseChanged={this.props.browseChanged}
                            getAnalyticsPerformanceSourceByUserMerchantProduct={this.props.getAnalyticsPerformanceSourceByUserMerchantProduct}
                            getAnalyticsPerformanceSourceByUserMerchantProductAggregate={this.props.getAnalyticsPerformanceSourceByUserMerchantProductAggregate}
                            getAnalyticsPerformanceSourceByLink={this.props.getAnalyticsPerformanceSourceByLink}
                            getAnalyticsPerformanceSalesSourceByUserMerchantProduct={this.props.getAnalyticsPerformanceSalesSourceByUserMerchantProduct}
                            getAnalyticsPerformanceSalesSourceByUserMerchantProductAggregate={this.props.getAnalyticsPerformanceSalesSourceByUserMerchantProductAggregate}
                            getAnalyticsPerformanceSalesSourceByLink={this.props.getAnalyticsPerformanceSalesSourceByLink}
                            performanceSourceUserMerchantProduct={this.props.performanceSourceUserMerchantProduct}
                            performanceSalesSourceUserMerchantProduct={this.props.performanceSalesSourceUserMerchantProduct}
                            analyticsPerformanceSourceByUserMerchantProductLoading={this.props.analyticsPerformanceSourceByUserMerchantProductLoading}
                            analyticsPerformanceSalesSourceByUserMerchantProductLoading={this.props.analyticsPerformanceSalesSourceByUserMerchantProductLoading}
                            clearSourceSort={this.props.clearSourceSort}
                            clearPerformance={this.props.clearPerformance}
                            clearSalesSourceSort={this.props.clearSalesSourceSort}
                            handleSourceReferrerClick={this.props.handleSourceReferrerClick}
                            handleSalesSourceReferrerClick={this.props.handleSalesSourceReferrerClick}
                            handleSourceClicksSort={this.props.handleSourceClicksSort}
                            sourceSortAscending={this.props.sourceSortAscending}
                            sourceSortReferrer={this.props.sourceSortReferrer}
                            salesSourceSortAscending={this.props.salesSourceSortAscending}
                            salesSourceSortReferrer={this.props.salesSourceSortReferrer}
                            userId={this.props.userId}
                            startDate={this.props.startDate}
                            endDate={this.props.endDate}
                        />
                        :
                        this.state.tab === 2 ?
                            <AnalyticsByCommissionDetailItemTable
                                loggedInUser={this.props.loggedInUser}
                                commissionDetailItemsAnalytics={this.props.commissionDetailItemsAnalytics}
                                analyticsByCommissionDetailItemLoading={this.props.analyticsByCommissionDetailItemLoading}
                                getCommissionDetailItemsAnalytics={this.props.getCommissionDetailItemsAnalytics}
                                date={this.props.date}
                                deviceSize={this.props.deviceSize}
                                handleGetLink={this.props.handleGetLink}
                                handleSourceClicksSort={this.props.handleSourceClicksSort}
                                handleSalesSourceReferrerClick={this.props.handleSalesSourceReferrerClick}
                                handleCommissionDetailItemsSortSelect={this.props.handleCommissionDetailItemsSortSelect}
                                commissionDetailItemsSelectOptions={this.props.commissionDetailItemsSelectOptions}
                                commissionDetailItemsSortDescending={this.props.commissionDetailItemsSortDescending}
                                resetPerformanceTabs={this.props.resetPerformanceTabs}
                                performanceSalesSourceCommissionDetailItem={this.props.performanceSalesSourceCommissionDetailItem}
                                clearSalesSourceSort={this.props.clearSalesSourceSort}
                                startDate={this.props.startDate}
                                endDate={this.props.endDate}
                                userChanged={this.props.merchantUserChanged ?? false}
                                aggregateChanged={this.props.aggregateChanged}
                                display={this.props.display}
                                browseChanged={this.props.browseChanged}
                                clearPerformance={this.props.clearPerformance}
                                userId={this.props.userId}
                                getAnalyticsPerformanceSalesSourceByCommissionDetailItem={this.props.getAnalyticsPerformanceSalesSourceByCommissionDetailItem}
                                salesSourceSortAscending={this.props.salesSourceSortAscending}
                                salesSourceSortReferrer={this.props.salesSourceSortReferrer}
                            />
                        :
                        this.state.tab === 3 ?
                            <AnalyticsByMerchantTable
                                getAnalyticsPerformanceSalesSourceByMerchant={this.props.getAnalyticsPerformanceSalesSourceByMerchant}
                                analyticsPerformanceSalesSourceByMerchantLoading={this.props.analyticsPerformanceSalesSourceByMerchantLoading}
                                performanceSalesSourceMerchant={this.props.performanceSalesSourceMerchant}
                                salesSourceSortAscending={this.props.salesSourceSortAscending}
                                salesSourceSortReferrer={this.props.salesSourceSortReferrer}
                                handleSalesSourceReferrerClick={this.props.handleSalesSourceReferrerClick}
                                clearSalesSourceSort={this.props.clearSalesSourceSort}
                                loggedInUser={this.props.loggedInUser}
                                merchantAnalytics={this.props.merchantAnalytics}
                                merchantAnalyticsLoading={this.props.analyticsByMerchantLoading}
                                getMerchantAnalytics={this.props.getMerchantAnalytics}
                                date={this.props.date}
                                deviceSize={this.props.deviceSize}
                                handleGetLink={this.props.handleGetLink}
                                handleMerchantSortSelect={this.handleMerchantSortSelect}
                                merchantSelectOptions={this.props.merchantSelectOptions}
                                merchantSortDescending={this.props.merchantSortDescending}
                                resetPerformanceTabs={this.props.resetPerformanceTabs}
                                startDate={this.props.startDate}
                                endDate={this.props.endDate}
                                userChanged={this.props.merchantUserChanged ?? false}
                                aggregateChanged={this.props.aggregateChanged}
                                display={this.props.display}
                                browseChanged={this.props.browseChanged}
                                getAnalyticsPerformanceSourceByMerchant={this.props.getAnalyticsPerformanceSourceByMerchant}
                                performanceSourceMerchant={this.props.performanceSourceMerchant}
                                analyticsPerformanceSourceByMerchantLoading={this.props.analyticsPerformanceSourceByMerchantLoading}
                                clearSourceSort={this.props.clearSourceSort}
                                clearPerformance={this.props.clearPerformance}
                                handleSourceReferrerClick={this.props.handleSourceReferrerClick}
                                handleSourceClicksSort={this.props.handleSourceClicksSort}
                                sourceSortAscending={this.props.sourceSortAscending}
                                sourceSortReferrer={this.props.sourceSortReferrer}
                                userId={this.props.userId}
                                />
                            :
                            this.state.tab === 4 ?
                            <AnalyticsPerformanceByHour
                                        display={this.props.display}
                                        loggedInUser={this.props.loggedInUser}
                                        analytics={this.props.analyticsHourly}
                                        analyticsLoading={this.props.analyticsHourlyLoading}
                                        getAnalytics={this.props.getAnalyticsHourly}
                                        date={this.props.date}
                                        deviceSize={this.props.deviceSize}
                                        startDate={this.props.startDate}
                                        endDate={this.props.endDate}
                                        userChanged={this.props.hourlyUserChanged}
                                        userIdSelected={this.props.userId}
                                        aggregateChanged={this.props.aggregateChanged}
                            />
                     :
                this.state.tab === 5 ?
                <AnalyticsPerformanceByDay
                    display={this.props.display}
                    loggedInUser={this.props.loggedInUser}
                    analytics={this.props.analyticsDaily}
                    analyticsLoading={this.props.analyticsDailyLoading}
                    getAnalytics={this.props.getAnalyticsDaily}
                    date={this.props.date}
                    deviceSize={this.props.deviceSize}
                    startDate={this.props.startDate}
                    endDate={this.props.endDate}
                    userChanged={this.props.dailyUserChanged}
                    userIdSelected={this.props.userId}
                    aggregateChanged={this.props.aggregateChanged}
                />
                                    :
                    <></>
                }                
            </div>
        );
    }
}

export default AnalyticsByPerformance;