import React from "react";
import AccountBanner from "components/AccountBanner";
import { getRequest } from "sharedUtils/httpUtils";
import MUIDataTable from "mui-datatables";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import TextField from '@material-ui/core/TextField';
import ActionButton from "components/ActionButton";
import { convertToNumericMonthAndDayAndYearAndTime } from "sharedUtils/timeUtils";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import toast, { Toaster } from 'react-hot-toast';

const { DateTime } = require("luxon");

class CampaignReport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            startDate: DateTime.utc().minus({ months: 1 }).startOf('day'),
            endDate: DateTime.utc().startOf('day'),
            minDate: "1753-01-01",
            maxDate: "9999-12-31",
            reports: [],
            partners: [],
            value: [],
            reportsLoading: false,
            selected: [],
            options: [],
            loading: false,
            CampaignReportTable: {
                title: "Campaign Report",
                columns: [
                    {
                        name: "date",
                        label: "Date",
                        options: {
                            filter: true,
                            sort: true,
                            customBodyRender: (value) => {
                                return convertToNumericMonthAndDayAndYearAndTime(value, false).replace(',', '');
                            }
                        }
                    },
                    {
                        name: "userName",
                        label: "UserName",
                        options: {
                            filter: true,
                            sort: true,
                        },
                    },
                    {
                        name: "userId",
                        label: "UserId",
                        options: {
                            filter: true,
                            sort: true,
                        },
                    },
                    {
                        name: "name",
                        label: "Name",
                        options: {
                            filter: true,
                            sort: true,
                        },
                    },
                    {
                        name: "merchantId",
                        label: "Merchant ID",
                        options: {
                            filter: true,
                            sort: true,
                        },
                    },
                    {
                        name: "clicks",
                        label: "Clicks",
                        options: {
                            filter: true,
                            sort: true,
                        },
                    },
                    {
                        name: "gmv",
                        label: "GMV",
                        options: {
                            filter: true,
                            sort: true,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                return !!(value) ? "$" + value.toFixed(2) : "$0";
                            },
                        },
                    },
                    {
                        name: "salesCount",
                        label: "SalesCount",
                        options: {
                            filter: true,
                            sort: true,
                        },
                    },
                ],
                options: {
                    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
                        <div className="d-flex flex-row align-items-center mx-1">
                            <div className="dropdown inline-block mx-1 MuiTypography-subtitle1">
                                <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Action
                                </button>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                    <button className="dropdown-item" type="button" onClick={() => this.confirmDelete(selectedRows, displayData)}>
                                        Delete Selected Content
                                    </button>
                                    <button className="dropdown-item" type="button" onClick={() => this.confirmPublishSelected(selectedRows, displayData)}>
                                        Publish Selected Content
                                    </button>
                                </div>
                            </div>
                        </div>
                    ),
                    selectableRows: "multiple",
                    sortOrder: {
                        name: 'userId',
                        direction: 'asc'
                    },
                },
                getTheme: () => createTheme({
                    overrides: {
                        MUIDataTableBodyRow: {
                            root: {
                                '&:nth-child(odd)': {
                                    backgroundColor: '#F9F9F9'
                                },
                                cursor: 'pointer'
                            }
                        },
                    }
                })
            },
        };
    }

    breadCrumbList = [{ link: '/Admin', text: 'Admin' }, { link: '/Admin/Analytics/Analytics', text: 'Analytics', active: true }];

    componentDidMount = () => {
        window.scrollTo({ left: 0, top: 0, behavior: 'auto' });
    };

    handleStartDateChange = (event) => {
        const startDateRaw = event.target.value;
        if (startDateRaw.length === 0) return;

        const startDate = DateTime.fromISO(startDateRaw).toUTC().startOf('day');
        if (startDate.isValid) {
            this.setState({ startDate });
        } else {
            console.error("Invalid start date entered:", startDateRaw);
        }
    };

    handleEndDateChange = (event) => {
        const endDateRaw = event.target.value;
        if (endDateRaw.length === 0) return;

        const endDate = DateTime.fromISO(endDateRaw).toUTC().startOf('day');
        if (endDate.isValid) {
            this.setState({ endDate });
        } else {
            console.error("Invalid end date entered:", endDateRaw);
        }
    };

    handleWeekToDate = () => {
        const startDate = DateTime.utc().minus({ weeks: 1 }).startOf('day');
        this.setState({ startDate, endDate: DateTime.utc().startOf('day') });
    };

    handleMonthToDate = () => {
        const startDate = DateTime.utc().minus({ months: 1 }).startOf('day');
        this.setState({ startDate, endDate: DateTime.utc().startOf('day') });
    };

    handleYearToDate = () => {
        const startDate = DateTime.utc().minus({ years: 1 }).startOf('day');
        this.setState({ startDate, endDate: DateTime.utc().startOf('day') });
    };

    getReports = async () => {
        let url = `api/Account/GetCampaignReports`;
        const { startDate, endDate } = this.state;
        const offset = DateTime.now().offset;

        if (startDate) url += `?startDate=${startDate.toSQL({ includeOffset: false })}`;
        if (endDate) url += `&endDate=${endDate.plus({ days: 1 }).startOf('day').toSQL({ includeOffset: false })}`;
        url += `&offset=${offset}&merchantId=${this.state.selected[0].id}`;

        this.setState({ reportsLoading: true });
        const reports = await getRequest(url);
        this.setState({ reports, reportsLoading: false });
    };

    handleGetReport = () => {
        if (this.state.selected.length > 0 && this.state.selected[0]?.id) {
            this.getReports();
        } else {
            toast.error(
                <div className="p-2">
                    <h4 className="text-center" style={{ color: "red" }}>Select a merchant before getting a report!</h4> 
                </div>,
                {
                    duration: 3000,
                    position: 'top-center',
                    icon: null,
                }
            );
        }
    };

    setSelected = (selected) => {
        if (selected != null) {
            this.setState({ selected });
        }
    };

    fetchMerchants = async (query) => {
        this.setState({ loading: true });
        const options = await getRequest(`/api/Merchant/MerchantSearchByMerchantName?merchantName=${query}`);
        this.setState({ options, loading: false });
    };

    render() {
        return (
            <>
                <Toaster />

                <section>
                    <AccountBanner breadCrumbList={this.breadCrumbList}>
                        <h1 className="mb-4">Campaign Report</h1>
                        <p className="mb-0">Reporting on creator activity such as clicks and sales for a particular brand</p>
                    </AccountBanner>
                </section>

                <section className="gray-bg mb-0 pt-2">
                    <div className="container pt-0 pb-0">
                        <div className="search-interest py-1 mx-auto">
                            <div className="search-block d-md-flex justify-content-between">
                                <div>
                                    <AsyncTypeahead
                                        id="merchantSearch"
                                        labelKey="name"
                                        defaultSelected={this.state.value}
                                        clearButton
                                        onSearch={this.fetchMerchants}
                                        onChange={e => this.setSelected(e)}
                                        isLoading={this.state.loading}
                                        options={this.state.options}
                                        placeholder="Search for merchants..." />
                                </div>
                                <ActionButton className={`btn btn-primary`}
                                    style={{ height: "38px" }}
                                    onClick={this.handleGetReport}
                                >
                                    Get Report
                                </ActionButton>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="gray-bg mb-0 pt-2">
                    {
                        !["sm"].includes(this.props.deviceSize) ?
                            <></>
                            :
                            <div className="d-flex w-100 justify-content-end pr-2" style={{ fontFamily: "'Archivo', sans-serif" }}>
                                <div onClick={this.handleWeekToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', border: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '4px 0 0 4px' }}>1w</div>
                                <div onClick={this.handleMonthToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>1m</div>
                                <div onClick={this.handleYearToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', border: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '0 4px 4px 0' }}>1y</div>
                            </div>
                    }
                    <div className="container pt-0 pb-0">

                        <div className={`mx-0 d-flex justify-content-between pb-4 ${["sm"].includes(this.props.deviceSize) ? "ml-2 mr-2" : "pt-4"} mx-auto`}>

                            <div className={`d-flex ${["sm"].includes(this.props.deviceSize) ? "w-100 pt-3 mx-2" : ""} justify-content-${["sm"].includes(this.props.deviceSize) ? "between" : "start"}`} style={{ gap: ["sm"].includes(this.props.deviceSize) ? "" : "30px", fontFamily: "'Archivo',sans-serif" }}>
                                <p className="ml-3 pt-3 mb-0" style={{ color: "red", display: this.state.dateError ? "" : "none" }}>Start Date must be before the End Date</p>

                                <div className="d-flex flex-column">
                                    <label className="mb-0">Start Date:</label>
                                    <TextField
                                        id="start-date"
                                        type="date"
                                        value={this.state.startDate.toISODate()}
                                        onChange={this.handleStartDateChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            style: { fontFamily: "'Archivo', sans-serif" },
                                        }} />
                                </div>
                                <div className="d-flex flex-column">
                                    <label className="mb-0">End Date:</label>
                                    <TextField
                                        id="end-date"
                                        type="date"
                                        value={this.state.endDate.toISODate()}
                                        onChange={this.handleEndDateChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            style: { fontFamily: "'Archivo', sans-serif" },
                                        }}
                                    />
                                </div>
                            </div>
                            {
                                ["sm"].includes(this.props.deviceSize) ?
                                    <></>
                                    :
                                    <div className="d-flex" style={{ fontFamily: "'Archivo', sans-serif" }}>
                                        <div onClick={this.handleWeekToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', border: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '4px 0 0 4px' }}>1w</div>
                                        <div onClick={this.handleMonthToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>1m</div>
                                        <div onClick={this.handleYearToDate} style={{ cursor: "pointer", width: '35px', height: '35px', backgroundColor: 'white', border: '1px solid lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '0 4px 4px 0' }}>1y</div>
                                    </div>
                            }

                        </div>
                    </div>

                </section>

                <section>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col">
                                <ThemeProvider theme={this.state.CampaignReportTable.getTheme()}>
                                    <MUIDataTable
                                        title={this.state.CampaignReportTable.title}
                                        data={this.state.reports}
                                        columns={this.state.CampaignReportTable.columns}
                                        options={this.state.CampaignReportTable.options}
                                    />
                                </ThemeProvider>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default CampaignReport;
