import React from "react";
import { withRouter } from 'react-router-dom';
import { getRequest } from "sharedUtils/httpUtils";
import InstagramIcon from "components/icons/InstagramIcon";
import FacebookIcon from "components/icons/FacebookIconNoLink";
import TikTokIcon from "components/icons/TikTokIcon";
import StravaIcon from "components/icons/StravaIcon";
import YoutubeIcon from "components/icons/YoutubeIcon";
import LoadSpinner from "components/LoadSpinner";
import { FormGroup, Input } from "reactstrap";
import CloseIcon from "components/icons/CloseIcon";
import ActionButton from "components/ActionButton";
import Modal from "components/Modal";
import ChangePasswordForm from "views/Admin/ChangePasswordForm";

class CreatorDashboardProfile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sectors: [],
            allSectors: [],
            stillAvailableSectors: [],
            fetchingSectors: true,
            editMode: false,
            toggleAdditionalCategories: false,
            showChangePasswordForm: false,
            userInformation: {
                id: 0,
                name: "",
                email: "",
                address: "",
                city: "",
                state: "",
                country: "",
                website: "",
                postalCode: "",
                phoneNumber: "",
                tagLine: "",
                bio: "",
                userName: "",
                googleUsername: "",
                facebookUsername: "",
                instagramUsername: "",
                tikTokUsername: "",
                stravaUsername: "",
            },
        };
    }

    componentDidMount = () => {
        this.parseUserInfo();
        this.getSectors();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.userInformation !== prevState.userInformation) {
            this.props.updateUserInformation(this.state.userInformation);
        }
        if (this.props.cancelEdit) {
            this.parseUserInfo();
            this.setPersistentSectors();
            this.props.resetCancelEdit();
        }
    }

    setPersistentSectors = () => {
        if (!!(this.props.persistentSectors)) {
            const sectors = this.props.persistentSectors;

            const stillAvailableSectors = this.state.allSectors.filter((allSector) => {
                return !sectors.find((sector) => {
                    return allSector.sectorId === sector.sectorId
                })
            });

            this.setState({ sectors, stillAvailableSectors });
        }
    }

    addUserSector = (sector) => {
        const sectors = [...this.state.sectors, sector];
        const stillAvailableSectors = this.state.stillAvailableSectors.filter(s => s.sectorId !== sector.sectorId)
        this.setState({ sectors, stillAvailableSectors });
        this.props.updateSectors(sectors);
    }

    deleteUserSector = (sector) => {
        const sectors = this.state.sectors.filter(s => s.sectorId !== sector.sectorId)
        this.setState({ sectors, stillAvailableSectors: [sector, ...this.state.stillAvailableSectors] });
        this.props.updateSectors(sectors);
    }

    parseUserInfo = () => {
        if (!!(this.props.userInformation)) {
            const propsUserInformation = !!(this.props.persistentUserInformation) ? this.props.persistentUserInformation : this.props.userInformation;

            const userInformation = {
                name: propsUserInformation.name ?? "",
                email: propsUserInformation.email ?? "",
                address: propsUserInformation.address ?? "",
                website: propsUserInformation.website ?? "",
                phoneNumber: propsUserInformation.phoneNumber ?? "",
                tagLine: propsUserInformation.tagLine ?? "",
                bio: propsUserInformation.bio ?? "",
                userName: propsUserInformation.userName ?? "",

                googleUsername: propsUserInformation.googleUsername ?? "",
                facebookUsername: propsUserInformation.facebookUsername ?? "",
                instagramUsername: propsUserInformation.instagramUsername ?? "",
                tikTokUsername: propsUserInformation.tikTokUsername ?? "",
                stravaUsername: propsUserInformation.stravaUsername ?? "",
            };
            this.setState({ userInformation })
        }
    }

    getSectors = async () => {
        let sectors = await getRequest('/api/Sector/GetSectors') ?? [];
        let userSectors = await getRequest(`/api/Sector/GetSectorsByUser?userid=${this.props.userInformation.id}`) ?? [];
        let activeSectors = [];

        for (var i = 0; i < userSectors.length; ++i) {
            for (var j = 0; j < sectors.length; ++j) {
                if (userSectors[i].sectorId === sectors[j].sectorId) {
                    activeSectors.push(sectors[j]);
                    break;
                }
            }
        }

        const stillAvailableSectors = sectors.filter(s => !activeSectors.includes(s));

        this.setState({
            allSectors: sectors,
            sectors: activeSectors,
            stillAvailableSectors,
            fetchingSectors: false,
            toggleAdditionalCategories: activeSectors?.length === 0 ? true : this.state.toggleAdditionalCategories,
        });
        this.props.setSectors(activeSectors, sectors);
    }

    renderSectors = () => {
        const { fetchingSectors, sectors } = this.state;

        if (fetchingSectors === true) {
            return <LoadSpinner />
        } else if (sectors.length === 0) {
            return <p className="" style={{ display: "inline" }}>None</p>
        }
        else
        {
            return sectors.map((sector, i) => {
                return <button key={i} className="btn d-flex align-items-center border" disabled>{sector.name}</button>
            });
        }
    }

    toggleResetPasswordModal = () => {
        this.setState({ showChangePasswordForm: !this.state.showChangePasswordForm });
    }

    displayChangePasswordForm = () => {
        return (
            <Modal isOpen={this.state.showChangePasswordForm} toggleModal={this.toggleResetPasswordModal}>
                <ChangePasswordForm user={this.props.userInformation?.id} toggleResetPasswordModal={this.toggleResetPasswordModal} />
            </Modal>
        )

    }


    onInputChange = (e) => {
        let formKey = e.target.name;
        let newData = e.target.value;

        if (formKey === 'bio') {
            e.target.style.height = 'inherit';
            e.target.style.height = `${e.target.scrollHeight}px`;
        }
        const userInformation = this.state.userInformation;
        userInformation[formKey] = newData;

        this.setState({ userInformation });
        this.props.updateUserInformation(userInformation);
    };

    displaySocials() {
        const { userInformation } = this.state;
        const { instagramUsername } = userInformation;
        const normalizedInstagram = userInformation?.instagramUsername?.startsWith("@") === true ? instagramUsername.substring(1) : instagramUsername;
        const socials = [
            {
                text: "Facebook",
                name: userInformation?.facebookUsername || null,
                icon: <FacebookIcon />,
                url: `facebook.com/${userInformation.facebookUsername}`,
                urlPrefix: 'www.facebook.com/',
                userInformationField: "facebookUsername"
            },
            {
                text: "Instagram",
                name: userInformation.instagramUsername || null,
                icon: <InstagramIcon fill="#000000" />,
                url: `instagram.com/${normalizedInstagram}`,
                urlPrefix: 'www.instagram.com/',
                userInformationField: "instagramUsername"
            },
            {
                text: "YouTube",
                name: userInformation?.googleUsername || null,
                icon: <YoutubeIcon />,
                url: `youtube.com/${userInformation.googleUsername}`,
                urlPrefix: 'www.youtube.com/',
                userInformationField: "googleUsername"
            },
            {
                text: "TikTok",
                name: userInformation?.tikTokUsername || null,
                icon: <TikTokIcon />,
                url: `tiktok.com/@${userInformation.tikTokUsername}`,
                urlPrefix: 'www.tiktok.com/@',
                userInformationField: "tikTokUsername"
            },
            {
                text: "Strava",
                name: userInformation?.stravaUsername || null,
                icon: <StravaIcon />,
                url: `strava.com/athletes/${userInformation.stravaUsername}`,
                urlPrefix: 'strava.com/athletes/',
                userInformationField: "stravaUsername"
            },
        ];

        return socials.map((social, i) => {
            if (this.props.editMode) {
                return (
                    <div className="col-lg-4" key={i}>
                        <div>
                            <span style={{ fontSize: "13px", color: "gray" }}>{social.text}</span>
                        </div>
                        <FormGroup className="form-group">
                            <div className="input-group">
                                <div className="input-group-append">
                                    <Input
                                        className={`form-control mr-0 pr-0`}
                                        name={social.urlPrefix}
                                        disabled={true}
                                        value={social.urlPrefix}
                                        style={{ fontSize: "12px", height: "38px" }}
                                    />
                                </div>
                                <Input
                                    className="form-control"
                                    name={social.userInformationField}
                                    onChange={(e) => this.onInputChange(e)}
                                    disabled={this.props.isSaving}
                                    value={this.state.userInformation[social.userInformationField]}
                                    style={{ marginLeft: "-18px" }}
                                />
                            </div>
                        </FormGroup>
                    </div>
                )
            }
            else if (userInformation[social.userInformationField] === undefined || userInformation[social.userInformationField].length === 0 || userInformation[social.userInformationField] === null) {
                return <span key={i}></span>
            } else {
                return (
                    <div className="col-lg-4" key={i}>
                        <div>
                            <span style={{ fontSize: "13px", color: "gray" }}>{social.text}</span>
                        </div>
                        <a href={"https://www." + social.url} className="" target="_blank">
                            <p className="" style={{ display: "inline" }}>{social.url}</p>
                        </a>
                    </div>
                )
            }
        });
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.props.saveBasicSettings(this.state.userInformation);
    }

    downwardChevron = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
            </svg>
        )
    }

    upwardChevron = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
            </svg>
        )
    }

    renderEditSectors = () => {
        return (
            <div style={{ maxWidth: "500px" }}>
                <div className="mb-1" style={{ fontSize: "12px", color: "gray" }}>What are you most passionate about?</div>

                <h6>My Passion List</h6>
                <div>
                    <ul>
                        {
                            this.state.sectors.length === 0 ?
                                <p className="" style={{ display: "inline" }}>None</p>
                            :
                            this.state.sectors.map((sector, i) => {
                                return (
                                    <li key={i} className="d-flex justify-content-between pr-5">
                                        <div>
                                            {sector.name}
                                        </div>
                                        <div style={{ cursor: "pointer" }} onClick={() => this.deleteUserSector(sector)}>
                                            <CloseIcon browse={true} width={15} height={15} />  
                                        </div>
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
                <div>
                    <div className="d-flex justify-content-between" style={{ cursor: "pointer" }} onClick={() => this.setState({ toggleAdditionalCategories: !this.state.toggleAdditionalCategories })}>
                        <h6>Additional Categories</h6>
                        {this.state.toggleAdditionalCategories ? this.upwardChevron() : this.downwardChevron()}
                    </div>
                    <div style={{ display: this.state.toggleAdditionalCategories ? "" : "none" }}>
                        <ul className="creator-dashboard-products-scroll pr-3" style={{ maxHeight: "200px", overflowY: "auto" }}>
                        {
                            this.state.stillAvailableSectors.map((sector, i) => {
                                return (
                                    <li key={i} className="d-flex justify-content-between">
                                        <div>
                                            {sector.name}
                                        </div>
                                        <div style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => this.addUserSector(sector)}>
                                            Add
                                        </div>
                                    </li>
                                );
                            })
                        }
                        </ul>
                    </div>
                </div>
            </div>
        
        );
    }

    render() {
        return (
            <div className="row mx-0 pt-0" style={{ display: this.props.hideDisplay ? "none" : "", fontFamily: "'Archivo', sans-serif", marginTop: this.props.deviceSize === "sm" ? "-25px" : "" }}>

                {this.displayChangePasswordForm()}

                <div className="col-lg-12">
                    <div className={`row ${["sm", "md"].includes(this.props.deviceSize) ? "" : "mb-3"}`}>
                        <div className={`col-lg-4 ${["sm", "md"].includes(this.props.deviceSize) ? "mb-3" : ""}`}>
                            <div>
                                <span style={{ fontSize: "13px", color: "gray" }}>Name</span>
                            </div>
                            {
                                this.props.editMode ?
                                    <FormGroup className="form-group">
                                        <Input
                                            className="form-control"
                                            name="name"
                                            onChange={(e) => this.onInputChange(e)}
                                            disabled={this.props.isSaving}
                                            value={this.state.userInformation.name}
                                        />
                                    </FormGroup> :
                                    <p className="" style={{ display: "inline" }}>{this.state.userInformation.name}</p>
                            }
                        </div>
                        <div className={`col-lg-4 ${["sm", "md"].includes(this.props.deviceSize) ? "mb-3" : ""}`}>
                            <div>
                                <span style={{ fontSize: "13px", color: "gray" }}>Email</span>
                            </div>
                            {
                                this.props.editMode ?
                                    <FormGroup className="form-group">
                                        <Input
                                            className="form-control"
                                            name="email"
                                            onChange={(e) => this.onInputChange(e)}
                                            disabled={this.props.isSaving}
                                            value={this.state.userInformation.email}
                                        />
                                    </FormGroup> :
                                    <p className="" style={{ display: "inline" }}>{this.state.userInformation.email}</p>
                            }
                        </div>
                        <div className={`col-lg-4 ${["sm", "md"].includes(this.props.deviceSize) ? "mb-3" : ""}`}>
                            <div>
                                <span style={{ fontSize: "13px", color: "gray" }}>Phone Number</span>
                            </div>
                            {
                                this.props.editMode ?
                                    <FormGroup className="form-group">
                                        <Input
                                            className="form-control"
                                            name="phoneNumber"
                                            onChange={(e) => this.onInputChange(e)}
                                            value={this.state.userInformation.phoneNumber}
                                            disabled={this.props.isSaving}
                                        />
                                    </FormGroup> :
                                    <p className="" style={{ display: "inline" }}>{this.state.userInformation.phoneNumber}</p>
                            }
                        </div>
                    </div>

                    <div className={`row ${["sm", "md"].includes(this.props.deviceSize) ? "mt-0" : "mb-3"}`}>
                        <div className={`col-lg-4 ${["sm", "md"].includes(this.props.deviceSize) ? "mb-3" : ""}`}>
                            <div>
                                <span style={{ fontSize: "13px", color: "gray" }}>Personal Website</span>
                            </div>
                            {
                                this.props.editMode ?
                                    <FormGroup className="form-group">
                                        <div className="input-group">
                                            <div className="input-group-append" style={{ marginRight: "-70px" }}>
                                                <Input
                                                    className={`form-control mr-0 pr-0`}
                                                    name="websitePrepend"
                                                    disabled={true}
                                                    value="https://"
                                                    style={{ fontSize: "12px", height: "38px"}}
                                                />
                                            </div>
                                            <Input
                                                className="form-control"
                                                name="website"
                                                onChange={(e) => this.onInputChange(e)}
                                                disabled={this.props.isSaving}
                                                value={this.state.userInformation.website}
                                                style={{ marginLeft: "-18px" }}
                                            />
                                        </div>
                                    </FormGroup> :
                                    <a href={"https://" + this.state.userInformation.website} className="" target="_blank">
                                        <p className="" style={{ display: "inline" }}>{this.state.userInformation.website}</p>
                                    </a>                            }
                        </div>
                        <div className={`col-lg-8 ${["sm", "md"].includes(this.props.deviceSize) ? "my-4" : ""}`}>
                            {
                                this.props.editMode ?
                                    <div className={`position-relative ${["md", "sm"].includes(this.props.deviceSize) ? "" : "d-flex"} justify-content-${["sm"].includes(this.props.deviceSize) ? "between" : "end"}`} style={{ top: ["md","sm"].includes(this.props.deviceSize) ? "-12px" : "23px" }}>
                                        <ActionButton onClick={() => { this.setState({ showChangePasswordForm: true }) }} style={{ borderRadius: "3px", bottom: "5px", width: ["md", "sm"].includes(this.props.deviceSize) ? " 100%" : "" }} className="btn btn-outline-brand">Change Password</ActionButton>
                                    </div> :
                                    <></>}
                        </div>
                    </div>

                    {this.props.editMode ?
                        <div className="mb-3">
                            <div>
                                <span style={{ fontSize: "13px", color: "gray" }}>Tagline</span>
                            </div>
                            <FormGroup className="form-group">
                                <Input
                                    className="form-control"
                                    name="tagLine"
                                    onChange={(e) => this.onInputChange(e)}
                                    value={this.state.userInformation.tagLine}
                                    disabled={this.props.isSaving}
                                />
                            </FormGroup>
                        </div>
                        :
                        this.state.userInformation?.tagLine == undefined || this.state.userInformation?.tagLine == null || this.state.userInformation?.tagLine === "" ?
                        <></>
                        :
                        <div className="mb-3">
                            <div>
                                <span style={{ fontSize: "13px", color: "gray" }}>Tagline</span>
                            </div>
                            <p className="" style={{ display: "inline" }}>{this.state.userInformation.tagLine}</p>
                        </div>
                    }

                    {this.props.editMode ?
                        <div className="mb-3">
                            <div>
                                <span style={{ fontSize: "13px", color: "gray" }}>Bio</span>
                            </div>
                            <FormGroup className="form-group">
                                <textarea
                                    className="form-control"
                                    name="bio"
                                    onChange={(e) => this.onInputChange(e)}
                                    value={this.state.userInformation.bio}
                                    style={{ overflow: "hidden" }}
                                    disabled={this.props.isSaving}
                                />
                            </FormGroup>
                        </div>
                        :
                        this.state.userInformation?.bio == undefined || this.state.userInformation?.bio == null || this.state.userInformation?.bio === "" ?                          
                        <></>
                            :
                        <div className="mb-3">
                            <div>
                                <span style={{ fontSize: "13px", color: "gray" }}>Bio</span>
                            </div>
                            <p className="" style={{ display: "inline" }}>{this.state.userInformation.bio}</p>
                        </div>
                    }

                    <div className="row mb-3" style={{ marginTop: this.props.deviceSize === "sm" ? "" : "" }}>
                        {this.displaySocials()}
                    </div>

                    {
                        this.props.editMode ?
                            this.renderEditSectors()
                            :
                        this.state.sectors == undefined || this.state.sectors == null || this.state.sectors == {} ?
                        <></> :
                        <div>
                            <div className="mb-2">
                                <div className="mb-1" style={{ fontSize: "12px", color: "gray" }}>What are you most passionate about?</div>
                                <div style={{ fontSize: "13px", color: "gray" }}>My Passions</div>
                            </div>
                            <div className="tag-content-sm d-flex flex-wrap py-0">
                                {this.renderSectors()}
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(CreatorDashboardProfile);
