let usStates = [
    {
        "id": 1456,
        "name": "Alabama",
        "state_code": "AL"
    },
    {
        "id": 1400,
        "name": "Alaska",
        "state_code": "AK"
    },
    {
        "id": 1424,
        "name": "American Samoa",
        "state_code": "AS"
    },
    {
        "id": 1434,
        "name": "Arizona",
        "state_code": "AZ"
    },
    {
        "id": 1444,
        "name": "Arkansas",
        "state_code": "AR"
    },
    {
        "id": 1402,
        "name": "Baker Island",
        "state_code": "UM-81"
    },
    {
        "id": 1416,
        "name": "California",
        "state_code": "CA"
    },
    {
        "id": 1450,
        "name": "Colorado",
        "state_code": "CO"
    },
    {
        "id": 1435,
        "name": "Connecticut",
        "state_code": "CT"
    },
    {
        "id": 1399,
        "name": "Delaware",
        "state_code": "DE"
    },
    {
        "id": 1437,
        "name": "District of Columbia",
        "state_code": "DC"
    },
    {
        "id": 1436,
        "name": "Florida",
        "state_code": "FL"
    },
    {
        "id": 1455,
        "name": "Georgia",
        "state_code": "GA"
    },
    {
        "id": 1412,
        "name": "Guam",
        "state_code": "GU"
    },
    {
        "id": 1411,
        "name": "Hawaii",
        "state_code": "HI"
    },
    {
        "id": 1398,
        "name": "Howland Island",
        "state_code": "UM-84"
    },
    {
        "id": 1460,
        "name": "Idaho",
        "state_code": "ID"
    },
    {
        "id": 1425,
        "name": "Illinois",
        "state_code": "IL"
    },
    {
        "id": 1440,
        "name": "Indiana",
        "state_code": "IN"
    },
    {
        "id": 1459,
        "name": "Iowa",
        "state_code": "IA"
    },
    {
        "id": 1410,
        "name": "Jarvis Island",
        "state_code": "UM-86"
    },
    {
        "id": 1428,
        "name": "Johnston Atoll",
        "state_code": "UM-67"
    },
    {
        "id": 1406,
        "name": "Kansas",
        "state_code": "KS"
    },
    {
        "id": 1419,
        "name": "Kentucky",
        "state_code": "KY"
    },
    {
        "id": 1403,
        "name": "Kingman Reef",
        "state_code": "UM-89"
    },
    {
        "id": 1457,
        "name": "Louisiana",
        "state_code": "LA"
    },
    {
        "id": 1453,
        "name": "Maine",
        "state_code": "ME"
    },
    {
        "id": 1401,
        "name": "Maryland",
        "state_code": "MD"
    },
    {
        "id": 1433,
        "name": "Massachusetts",
        "state_code": "MA"
    },
    {
        "id": 1426,
        "name": "Michigan",
        "state_code": "MI"
    },
    {
        "id": 1438,
        "name": "Midway Atoll",
        "state_code": "UM-71"
    },
    {
        "id": 1420,
        "name": "Minnesota",
        "state_code": "MN"
    },
    {
        "id": 1430,
        "name": "Mississippi",
        "state_code": "MS"
    },
    {
        "id": 1451,
        "name": "Missouri",
        "state_code": "MO"
    },
    {
        "id": 1446,
        "name": "Montana",
        "state_code": "MT"
    },
    {
        "id": 1439,
        "name": "Navassa Island",
        "state_code": "UM-76"
    },
    {
        "id": 1408,
        "name": "Nebraska",
        "state_code": "NE"
    },
    {
        "id": 1458,
        "name": "Nevada",
        "state_code": "NV"
    },
    {
        "id": 1404,
        "name": "New Hampshire",
        "state_code": "NH"
    },
    {
        "id": 1417,
        "name": "New Jersey",
        "state_code": "NJ"
    },
    {
        "id": 1423,
        "name": "New Mexico",
        "state_code": "NM"
    },
    {
        "id": 1452,
        "name": "New York",
        "state_code": "NY"
    },
    {
        "id": 1447,
        "name": "North Carolina",
        "state_code": "NC"
    },
    {
        "id": 1418,
        "name": "North Dakota",
        "state_code": "ND"
    },
    {
        "id": 1431,
        "name": "Northern Mariana Islands",
        "state_code": "MP"
    },
    {
        "id": 4851,
        "name": "Ohio",
        "state_code": "OH"
    },
    {
        "id": 1421,
        "name": "Oklahoma",
        "state_code": "OK"
    },
    {
        "id": 1415,
        "name": "Oregon",
        "state_code": "OR"
    },
    {
        "id": 1448,
        "name": "Palmyra Atoll",
        "state_code": "UM-95"
    },
    {
        "id": 1422,
        "name": "Pennsylvania",
        "state_code": "PA"
    },
    {
        "id": 1449,
        "name": "Puerto Rico",
        "state_code": "PR"
    },
    {
        "id": 1461,
        "name": "Rhode Island",
        "state_code": "RI"
    },
    {
        "id": 1443,
        "name": "South Carolina",
        "state_code": "SC"
    },
    {
        "id": 1445,
        "name": "South Dakota",
        "state_code": "SD"
    },
    {
        "id": 1454,
        "name": "Tennessee",
        "state_code": "TN"
    },
    {
        "id": 1407,
        "name": "Texas",
        "state_code": "TX"
    },
    {
        "id": 1432,
        "name": "United States Minor Outlying Islands",
        "state_code": "UM"
    },
    {
        "id": 1413,
        "name": "United States Virgin Islands",
        "state_code": "VI"
    },
    {
        "id": 1414,
        "name": "Utah",
        "state_code": "UT"
    },
    {
        "id": 1409,
        "name": "Vermont",
        "state_code": "VT"
    },
    {
        "id": 1427,
        "name": "Virginia",
        "state_code": "VA"
    },
    {
        "id": 1405,
        "name": "Wake Island",
        "state_code": "UM-79"
    },
    {
        "id": 1462,
        "name": "Washington",
        "state_code": "WA"
    },
    {
        "id": 1429,
        "name": "West Virginia",
        "state_code": "WV"
    },
    {
        "id": 1441,
        "name": "Wisconsin",
        "state_code": "WI"
    },
    {
        "id": 1442,
        "name": "Wyoming",
        "state_code": "WY"
    }
]

export default usStates;